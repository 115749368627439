import { AxiosResponse } from 'axios';
import useFetchData from '../hooks/useFetchData';
import Loader from './Loader';

interface DataObject extends Object {
  data: unknown;
}
interface WithDataProps {
    WrappedComponent: React.ComponentType<any>;
    fetchData: (eoaId: string, access_token: string) => Promise<AxiosResponse<any, any>> | Promise<PromiseSettledResult<AxiosResponse<any, any>>[] | DataObject>;
    errorMessage: string;
    options?: {[key: string]: string[]};
}

export function withData<T>({WrappedComponent, fetchData, errorMessage, options}: WithDataProps) {

  return (props: T) => {
    const { isPending, error, response } = useFetchData({
      fetchData: fetchData,
      errorMessage: errorMessage,
      options: options
    });
  
    if (isPending) return <Loader />;
    if (error || !response) return <p className="center-text">{errorMessage.replace('Error occured while fetching', 'Missing')}</p>;

    return Array.isArray(response) ? 
      <WrappedComponent data={response} {...props} /> 
      : 
      <WrappedComponent data={response?.data} {...props} />;
  };
};