import CustomButton from '../reusable/CustomButton';
import CustomTable, { TableHeaderData } from '../reusable/CustomTable';
import TranscriptsBySubjectArea from './TranscriptsBySubjectArea';
import { Transcript } from '../../interfaces/TranscriptsInterface';
import Loader from '../reusable/Loader';
import TranscriptSelectionTableRow from './TranscriptSelectionTableRow';
import InfoIcon from '../assets/info-icon.svg';
import PrintIcon from '../assets/print.svg';
import CustomTooltip from '../reusable/CustomTooltip';
import { useTranscriptSelectionOutletContext } from '../hooks/useTranscriptSelectionOutletContext';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { formatHelperTextCount } from './utilityFunctions';
import { TRANSCRIPTS_CATEGORIES, TRANSCRIPTS_CATEGORIES_LABELS, TRANSCRIPTS_CATEGORIES_SELECTION_LIMITS } from '../../constants';

import './TranscriptSelectionTables.css';

interface TranscriptSelectionTablesProps {
  isSideDrawerOpen: boolean;
}

const tableHeaderData: TableHeaderData<Transcript>[] = [
  { key: '', label: '' },
  { key: 'studentName', label: 'Student' },
  { key: 'gpa', label: 'GPA' },
  { key: 'credits', label: 'Credits' },
  { key: 'raceEth', label: 'Race/ethnicity' },
  { key: 'gender', label: 'Gender' },
  { key: 'disabilityStatus', label: 'Students with disabilities' },
  { key: 'englishLearner', label: 'English language learners' },
  { key: 'lowIncome', label: 'Low income' },
  { key: '', label: '' },
];

const renderSelectionLimitText = (selectedCount: number, selectionLimit: number) => {
  const limitExceeded = selectedCount > selectionLimit;

  return (
    <p className={limitExceeded ? 'selection-error-message' : ''}>
      {`${selectedCount}/${selectionLimit} selected`}
      {limitExceeded && ` - Select up to ${formatHelperTextCount(selectionLimit, 'student')}`}
    </p>
  );
};

const renderHeaderHelperText = (categoryName: string, selectionLimit: number) => {

  const selectionLimitText = <> Select <span className="bolded">{formatHelperTextCount(selectionLimit, 'student')}</span> </>;

  switch(categoryName) {
  case TRANSCRIPTS_CATEGORIES.RIGOROUS_ACADEMIC:
    return (
      <> {selectionLimitText} from the highest achievers to represent the High flier category. </>
    );
  case TRANSCRIPTS_CATEGORIES.ON_THE_CUSP: 
    return (
      <> {selectionLimitText} based on their satisfaction of college eligibility criteria to represent the On the cusp category. </>
    );
  case TRANSCRIPTS_CATEGORIES.STRUGGLING: 
    return (
      <> {selectionLimitText} based on lack of college eligibility criteria to represent the Struggling category. </>
    );
  }
};

function TranscriptSelectionTables({ isSideDrawerOpen }: TranscriptSelectionTablesProps) {
  const {is_finalized, selectedTranscriptsIdsByCategory, categoriesOrder, transcripts} = useTranscriptSelectionOutletContext();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  if(!transcripts) return <Loader />;
  
  return (
    <div className="transcript-selection-tables-container">
      {categoriesOrder.map((categoryName) => {
        const transcriptsInCategory = transcripts[categoryName];
        const selectedCount = selectedTranscriptsIdsByCategory[categoryName].length;
        return (
          <div className="transcript-selection-category-container" key={categoryName}>
            <div className="transcript-selection-category-top-section">
              <div className="transcript-selection-category-header">  
                <div className="title">
                  <p>{TRANSCRIPTS_CATEGORIES_LABELS[categoryName]}</p>
                  <CustomTooltip 
                    title="Test title"
                    placement="top"
                    arrow={true}>
                    <img src={InfoIcon} alt="info icon"/>
                  </CustomTooltip>
                </div>
                {renderHeaderHelperText(categoryName, TRANSCRIPTS_CATEGORIES_SELECTION_LIMITS[categoryName])}
              </div>
              <div className="transcript-selection-category-header-actions">
                {renderSelectionLimitText(selectedCount, TRANSCRIPTS_CATEGORIES_SELECTION_LIMITS[categoryName])}
                <div className="buttons-container">
                  <CustomButton
                    variant="text"
                    buttonText="Print All"
                    icon={<img src={PrintIcon} alt="download"></img>}
                    iconPosition="start"
                    buttonAction={() => console.log('print all')}
                  />
                </div>
              </div>
            </div>
            <CustomTable
              tableHeaderData={tableHeaderData}
              headerTooltip={isSmallScreen || isSideDrawerOpen}>
              {transcriptsInCategory.map((transcript, index) => (
                <TranscriptSelectionTableRow key={transcript.id} row={transcript} rowIndex={index} category={categoryName} />
              ))}
            </CustomTable>
          </div>
        );
      })}
      { is_finalized ?
        <div className="transcript-selection-category-container transcripts-view">
          <TranscriptsBySubjectArea />
        </div>
        :
        null
      }
    </div>
  );
}

export default TranscriptSelectionTables;