import { FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Radio, Select } from '@mui/material';
import { RadioGroup } from '@mui/material';
import useUploadCardData from '../hooks/useUploadCardData';
import CustomModal from '../reusable/CustomModal';
import { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useSchools from '../hooks/useSchools';
import CustomButton from '../reusable/CustomButton';
import type { Dayjs } from 'dayjs';
import type { ElementType } from 'react';
import type { SchoolFocusGroup } from '../../services/focusGroupService';
import { uploadFileToS3Bucket } from '../../services/uploadService';
import  { UploadedFileStatus } from '../../interfaces/UploadedFileDataInterface';  
import useUserDetails from '../hooks/useUserDetails';
import { postRequestUploadUrl } from '../../services/uploadService';
// import useUploadResults from '../hooks/useUploadResults';
// import { DATA_UPLOAD_REDUCER_ACTIONS } from '../data upload/dataUploadReducer';
import useErrorModal from '../hooks/useErrorModal';
import type { AxiosError, AxiosProgressEvent } from 'axios';

const ContentTypeStep = () => {
  const { fileName } = useUploadCardData();

  const recordingTypes = ['mp3', 'mp4', 'wav', 'aac'];
  const noteTypes = ['pdf', 'doc', 'docx', 'txt', 'rtf', 'html'];
  const extension = fileName.split('.').pop();

  const isRecording = recordingTypes.includes(extension as string);
  
  return <div>
    <p>Select type content type that best describes the file</p>
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      value={isRecording ? 'recordings' : 'notes'}
      name="radio-buttons-group"
    >
      <FormControlLabel value="recordings" control={<Radio />} label="Focus group recording" disabled={!isRecording}/>
      <FormHelperText id="recording-helper-text">
        Supported file format: {recordingTypes.join(', ')}
      </FormHelperText>
      <FormControlLabel value="notes" control={<Radio />} label="Session notes" disabled={isRecording} />
      <FormHelperText id="notes-helper-text">
        Supported file format: {noteTypes.join(', ')}
      </FormHelperText>
    </RadioGroup>
  </div>;
};

const SchoolSelectionStep = ({ school, setSchool }: { school: SchoolFocusGroup, setSchool: (school: SchoolFocusGroup) => void }) => {
  const { schools } = useSchools();

  if (!schools?.data) {
    return <div>
      <p>Loading schools...</p>
    </div>;
  }

  return <div>
    <p>Select the school for which this focus group was conducted</p>
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      name="radio-buttons-group"
      value={school?.id}
      onChange={(e) => {
        const school = schools.data?.find(school => school.id === e.target.value);
        setSchool(school as SchoolFocusGroup);
      }}
    >
      {schools.data.sort((a, b) => a.name.localeCompare(b.name)).map((school) => <FormControlLabel value={school.id} control={<Radio />} label={school.name} key={school.id} />)}
    </RadioGroup> 
  </div>;
};

const DateTimeSelectionStep = ({ date, time, setDate, setTime }: { date: Dayjs | null, time: number | null, setDate: (date: Dayjs | null) => void, setTime: (time: number | null) => void }) => {
  return <div>
    <p>Select the date and time of this focus group</p>
    <div>
      <DatePicker
        label="Focus group date"
        value={date}
        onChange={(date) => setDate(date)}
        sx={{ width: '200px' }}
        slotProps={{
          textField: {
            size: 'small'
          }
        }}
      />
    </div>
    <FormControl style={{ marginTop: '20px' }}>
      <InputLabel>Focus group time</InputLabel  >
      <Select
        label="Focus group time"
        style={{ width: '200px' }}
        size="small"
        value={time}
        onChange={(e) => setTime(e.target.value as number)}
      >
        {[7,8,9,10,11,12,13,14,15,16,17,18,19,20]
          .map((hour) => <MenuItem value={hour} key={hour}>{hour > 12 ? `${hour - 12}:00pm` : `${hour}:00am`}</MenuItem>)}
      </Select>
    </FormControl>
  </div>;
};

const ReviewSelectionStep = ({ school, date, time, setCurrentStepIndex }: { school: SchoolFocusGroup, date: Dayjs | null, time: number, setCurrentStepIndex: (stepIndex: number) => void }) => {
  
  const { data: userDetails } = useUserDetails();
  const { file, fileName, fileType, fileIdToReplace, handleClose, handleUploadProgress } = useUploadCardData();
  // const { dispatch } = useUploadResults();
  const { handleOpen: handleOpenErrorModal } = useErrorModal();

  const ReviewData = [{
    key: 'file',
    title: 'File',
    value: fileName
  }, {
    key: 'school',
    title: 'School',
    value: school?.name
  }, {
    key: 'date-time',
    title: 'Date and time',
    value: `${date?.format('MM/DD/YYYY')} - ${time ? `${time}:00${time > 12 ? 'pm' : 'am'}` : ''}`
  }];

  const handleUpload = (progressEvent: AxiosProgressEvent) => {
    if (progressEvent.total) {
      const percentsCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      handleUploadProgress(percentsCompleted);
    }
  };

  const handleSubmit = async () => {
    
    if (!userDetails) return;

    const fileData = {
      file_id: '',
      file_name: fileName,
      upload_file_type: fileType,
      status: UploadedFileStatus.UPLOAD,
      statistics: [],
      results_summary: [],
      user_provided_file_metadata: {
        school_id: school?.id,
        focus_group_datetime: `${date?.hour(time).format()}`
      }
    };

    try {
      handleClose();      
  
      const urlResponse = await postRequestUploadUrl(userDetails.eoaId, userDetails.access_token, {
        file_name: fileName,
        user_provided_file_metadata: fileData.user_provided_file_metadata,
        upload_file_type: fileType,
        replaces_file_id: fileIdToReplace || undefined
      });

      const fileId = urlResponse.data.s3_key.split('/').at(-1) as string;
      fileData.file_id = fileId;

      // if (!fileIdToReplace) {
      //   dispatch({
      //     type: DATA_UPLOAD_REDUCER_ACTIONS.UPDATE_UPLOADED_FILES_DATA,
      //     payload: fileData
      //   });
      // }
      // else {
      //   dispatch({
      //     type: DATA_UPLOAD_REDUCER_ACTIONS.REPLACE_UPLOADED_FILE,
      //     payload: { idToReplace: fileIdToReplace, newFileData: fileData }
      //   });
      // }

      await uploadFileToS3Bucket(urlResponse.data.presigned_url, file, handleUpload);

      fileData.status = UploadedFileStatus.PROCESSING;
      // dispatch({ type: DATA_UPLOAD_REDUCER_ACTIONS.UPDATE_UPLOADED_FILES_DATA, payload: fileData });
    } catch (error) {
      fileData.status = UploadedFileStatus.UPLOAD_FAILED;
      // dispatch({ type: DATA_UPLOAD_REDUCER_ACTIONS.UPDATE_UPLOADED_FILES_DATA, payload: fileData });
      handleOpenErrorModal('Error occured while uploading file!', error as AxiosError);
    }
  };
  
  return <div>
    <p>Review and edit if needed, then submit your selections.</p>
    <div>
      {
        ReviewData.map((data) => {
          const stepIndex = STEPS.findIndex(step => step.key === data.key);

          return <div key={data.key}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p><b>{data.title}</b></p>
              {stepIndex > 0 && <CustomButton variant="text" buttonText="Edit" buttonAction={() => setCurrentStepIndex(stepIndex)} />}
            </div>
            <p>{data.value}</p>
          </div>;
        })
      }
    </div>
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
      <CustomButton variant="primary" size="medium" buttonText="Submit" buttonAction={handleSubmit} />
    </div>
  </div>;
};

type Step = {
  title: string;
  key: string;
  component: ElementType;
};

const STEPS = [{
  title: 'Select content type',
  key: 'content-type',
  component: ContentTypeStep,
}, {
  title: 'Select school',
  key: 'school',
  component: SchoolSelectionStep,
}, {
  title: 'Select date and time',
  key: 'date-time',
  component: DateTimeSelectionStep,
}, {
  title: 'Review selections',
  key: 'date-time',
  component: ReviewSelectionStep,
}] as Step[];

const FocusGroupUploadPrompt = () => {
  const { handleClose } = useUploadCardData();
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const currentStep = STEPS[currentStepIndex];
  const [school, setSchool] = useState<SchoolFocusGroup | null>(null);
  const [date, setDate] = useState<Dayjs | null>(null);
  const [time, setTime] = useState<number | null>(null);

  const Component = currentStep.component;

  const stepProps = {
    school,
    date,
    time,
    setSchool,
    setDate,
    setTime,
    setCurrentStepIndex
  };

  return (
    <CustomModal
      titleComponent={
        <div className="upload-prompt-header">
          <p className="no-margin steps-counter">Step {currentStepIndex + 1} of {STEPS.length}</p>
          <p className="no-margin step-name">
            {currentStep.title}
          </p>
        </div>
      }
      icon={false}
      isOpen={true}
      handleClose={handleClose}
      maxWidth="xs"
    >
      <Component {...stepProps} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        {
          currentStepIndex < STEPS.length - 1 && (
            <CustomButton variant="primary" size="medium" buttonText="Next" buttonAction={() => setCurrentStepIndex(currentStepIndex + 1)} />
          )
        }
      </div>
    </CustomModal>
  );
};

export default FocusGroupUploadPrompt;