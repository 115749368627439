import { Avatar, Button, Card, CardContent, CardHeader, FormControl, InputLabel, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Select } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import Divider from '@mui/material/Divider';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

import { fileTypes } from '../../constants';
import PageHeader from '../reusable/PageHeader';
import UploadCard from '../reusable/UploadCard';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import FocusGroupUploadPrompt from './FocusGroupUploadPrompt';
import type { SchoolFocusGroup } from '../../services/focusGroupService';
import { FocusGroupFileStatus, formatDate, useAllSchoolFocusGroups } from '../../services/focusGroupService';

const FocusGroupsOverview = () => {
  const [sort, setSort] = useState('recently-uploaded');

  const { schoolFocusGroups, loading: loadingSchoolFocusGroups } = useAllSchoolFocusGroups();

  if (loadingSchoolFocusGroups) {
    return <div>Loading...</div>;
  }
  
  const sortedSchools = schoolFocusGroups.sort((a, b) => {
    if (sort === 'recently-uploaded') {
      const getMostRecentFocusGroup = (school: SchoolFocusGroup) => {
        const focusGroups = school.focus_groups;
        const mostRecentFocusGroup = focusGroups.sort((a, b) => b.focus_group_datetime.localeCompare(a.focus_group_datetime))[0];
        return mostRecentFocusGroup;
      };

      const mostRecentA = getMostRecentFocusGroup(a);
      const mostRecentB = getMostRecentFocusGroup(b);

      // return schools with no focus groups last
      if (!mostRecentA) return 1;
      if (!mostRecentB) return -1;

      return mostRecentA.files[0].upload_datetime.localeCompare(mostRecentB.files[0].upload_datetime);
    }
    
    return a.name.localeCompare(b.name);
  });

  return <>
    <PageHeader title="Focus Group report"
      pageDescription="Automatically generate transcripts and key points to create focus group reports"
    />
    <div className="main-content">
      <div className="overview-step-cards">
        <Card style={{ width: '100%' }}>
          <div>
            <UploadCard title="Upload focus group files" 
              helperText={['Please upload focus group recordings and/or notes. Multiple files can be uploaded per school.']}
              uploadedFilesData={[]}
              fileType={fileTypes.FOCUS_GROUP_RECORDING}
              cardId="focus-group-transcripts"
              uploadPrompt={<FocusGroupUploadPrompt />}
              acceptFileTypes={{
                // Recordings
                'audio/mpeg': ['.mp3'],
                'video/mp4': ['.mp4'],
                'audio/wav': ['.wav'],
                'audio/aac': ['.aac'],
                // Notes
                'application/pdf': ['.pdf'],
                'application/msword': ['.doc'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                'text/plain': ['.txt'],
                'application/rtf': ['.rtf'],
                'text/html': ['.html']
              }}
            />
          </div>
        </Card>
        <Card style={{ width: '100%' }}>
          <CardHeader title="My Focus Groups" action={
            <FormControl fullWidth>
              <InputLabel id="sort-label">Sort</InputLabel>
              <Select
                labelId="sort-label"
                value={sort}
                displayEmpty
                label="Sort"
                onChange={(e) => setSort(e.target.value)}
                size="small"
                sx={{ width: '200px' }}
              >
                <MenuItem value="recently-uploaded">Recently Uploaded</MenuItem>
                <MenuItem value="alphabetical">Alphabetical</MenuItem>
              </Select>
            </FormControl>
          }/>  
          <CardContent>
            <List component="nav">
              {sortedSchools.filter(school => school.focus_groups.length > 0).map((school) => (
                <div key={school.id}>
                  <ListItem secondaryAction={
                    <Link to={`/school-focus-group/${school.id}`}>
                      <Button 
                        endIcon={<ArrowForwardIosOutlinedIcon />}
                        variant="outlined" 
                        size="small"
                      >View</Button>
                    </Link>
                  }>
                    <ListItemText primary={school.name} primaryTypographyProps={{ fontSize: '20px' }}/>
                  </ListItem>
                  <List component="div">
                    {school.focus_groups.map((focusGroup, i) => {
                      const { files } = focusGroup;
                      const [file] = files;

                      const fileStatus = file.file_status;

                      let secondaryText = null;
                      
                      const completedStatuses = [
                        FocusGroupFileStatus.UPLOAD_COMPLETE,
                        FocusGroupFileStatus.TRANSCRIPTION_COMPLETED,
                        FocusGroupFileStatus.EXTRACTED,
                        FocusGroupFileStatus.VALIDATED
                      ];

                      if (completedStatuses.includes(fileStatus)) {
                        secondaryText = `Uploaded ${formatDate(file.upload_datetime)}`;
                      } else if (fileStatus === FocusGroupFileStatus.UPLOAD_PENDING) {
                        secondaryText = 'Uploading...';
                      } else if (fileStatus === FocusGroupFileStatus.UPLOAD_FAILED) {
                        secondaryText = 'Upload failed';
                      } else if (fileStatus === FocusGroupFileStatus.VALIDATION_FAILED) {
                        secondaryText = 'Validation failed';
                      } else if (fileStatus === FocusGroupFileStatus.TRANSCRIPTION_IN_PROGRESS) {
                        secondaryText = 'Transcription in progress...';
                      } else if (fileStatus === FocusGroupFileStatus.TRANSCRIPTION_FAILED) {
                        secondaryText = 'Transcription failed';
                      } else if (fileStatus === FocusGroupFileStatus.EXTRACTION_FAILED) {
                        secondaryText = 'Extraction failed';
                      } else if (fileStatus === FocusGroupFileStatus.REPLACED) {
                        secondaryText = 'Replaced';
                      } else if (fileStatus === FocusGroupFileStatus.CORRUPT) {
                        secondaryText = 'Corrupt';
                      } else if (fileStatus === FocusGroupFileStatus.VIRUS_SCAN_FAILED) {
                        secondaryText = 'Virus scan failed';
                      }

                      return <div key={file.data_file_id} style={{ margin: '0px 0px 20px 40px' }}>
                        <div><b>{focusGroup.name} - {formatDate(focusGroup.focus_group_datetime)}</b></div>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <DescriptionOutlinedIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={file.original_filename} secondary={secondaryText} />
                        </ListItem>
                      </div>;
                    })}
                  </List>
                  <Divider />
                </div>
              ))}
            </List>
          </CardContent>
        </Card>
      </div>
    </div>
  </>;
};



export default FocusGroupsOverview;