import './Tag.css';

interface TagProps {
  type: 'pending' | 'progress' | 'completed' | 'info';
  text: string;
  className: string;
  size?: 'small' | 'medium';
}
function Tag({ type, text, className, size = 'medium' }: TagProps) {
  return (
    <div className={`tag ${type} ${className} ${size}`}>
      <p>{text}</p>
    </div>
  );
}

export default Tag;