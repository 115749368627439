import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import useUserDetails from '../components/hooks/useUserDetails';

// Types
export type SchoolFocusGroup = {
  id: string,
  name: string,
  focus_groups: FocusGroup[],
};

export type FocusGroup = {
  name: string,
  focus_group_datetime: string,
  files: FocusGroupFile[],
};

export type Trend = {
  id: string,
  name: string,
  responses: string[],
  quotes: Quote[],
};

export enum FocusGroupFileStatus {
  UPLOAD_PENDING = 'UPLOAD_PENDING',
  UPLOAD_FAILED = 'UPLOAD_FAILED',
  UPLOAD_COMPLETE = 'UPLOAD_COMPLETE',
  VALIDATED = 'VALIDATED',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  CORRUPT = 'CORRUPT',
  VIRUS_SCAN_FAILED = 'VIRUS_SCAN_FAILED',
  TRANSCRIPTION_IN_PROGRESS = 'TRANSCRIPTION_IN_PROGRESS',
  TRANSCRIPTION_FAILED = 'TRANSCRIPTION_FAILED',
  TRANSCRIPTION_COMPLETED = 'TRANSCRIPTION_COMPLETED',
  EXTRACTED = 'EXTRACTED',
  EXTRACTION_FAILED = 'EXTRACTION_FAILED',
  REPLACED = 'REPLACED',
}

export type FocusGroupFile = {
  data_file_id: string,
  file_size_bytes: number,
  file_type: string,
  original_filename: string,
  upload_datetime: string,
  transcript?: Quote[],
  notes?: Note[],
  file_status: FocusGroupFileStatus,
};

export type SchoolFocusGroupReport = {
  school_id: string,
  school_name: string,
  summary: string,
  themes: [
    {
      id: string,
      name: string,
      takeaways: string[],
      quotes: Quote[],
    }
  ]
}

export type Quote = {
  id: string,
  text: string,
  speaker_id: string,
};

export type Note = {
  id: string,
  title: string,
  text: string,
};

const BASE_URL = process.env.REACT_APP_BASE_URL;

const useRequest = ({ path }: { path: string }) => {
  const { data: userDetails } = useUserDetails();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (!userDetails) return;
    
    setLoading(true);
    
    axios.get(`${BASE_URL}/eoa/${userDetails.eoaId}${path}`, {
      headers: {
        Authorization: `Bearer ${userDetails.access_token}`
      },
    }).then((response) => {
      setData(response.data);
      setLoading(false);
    });

  }, [userDetails, path]);

  return { data, loading };
};


export const getSchoolFocusGroups = async ({ eoaId, access_token, schoolId }:{ eoaId: string, access_token: string, schoolId: string }) => {
  const url = `${BASE_URL}/eoa/${eoaId}/schools/${schoolId}/focus_groups`;
  
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${access_token}`
    },
  });

  return response.data;
};

export const useAllSchoolFocusGroups = (): { schoolFocusGroups: SchoolFocusGroup[], loading: boolean } => {
  const { data, loading } = useRequest({ path: '/focus_groups' });
  return { schoolFocusGroups: data?.schools ?? [] as SchoolFocusGroup[], loading };
};

export const useSchoolFocusGroup = ({ schoolId }: { schoolId: string }): { schoolFocusGroup: SchoolFocusGroup, loading: boolean } => {
  const { data, loading } = useRequest({ path: `/schools/${schoolId}/focus_groups` });
  return { schoolFocusGroup: data as SchoolFocusGroup, loading };
};

export const useSchoolFocusGroupReport = ({ schoolId }: { schoolId: string }): { schoolFocusGroupReport: SchoolFocusGroupReport, loading: boolean } => {
  const { data, loading } = useRequest({ path: `/schools/${schoolId}/focus_group_report` });
  return { schoolFocusGroupReport: data as SchoolFocusGroupReport, loading };
};

export const formatDate = (date: string) => {
  return dayjs(date).format('MM/DD/YYYY hh:mma');
};
