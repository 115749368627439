import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Button, Card, Divider, Tab, Tabs } from '@mui/material';
import PageHeader from '../reusable/PageHeader';
import { useRef } from 'react';
import Comments, { AuthorTag } from '../reusable/Comments';
import { ArrowBackIosOutlined } from '@mui/icons-material';
import Searchable from './Searchable';
import Highlighter from 'react-highlight-words';
import type { FocusGroup, FocusGroupFile, SchoolFocusGroup, SchoolFocusGroupReport } from '../../services/focusGroupService';
import { formatDate, useSchoolFocusGroupReport, useSchoolFocusGroup } from '../../services/focusGroupService';

const SchoolSummaryTab = ({ schoolFocusGroupReport }: { schoolFocusGroupReport: SchoolFocusGroupReport }) => {

  return <div>
    <h2>School Overview</h2>
    <div style={{
      whiteSpace: 'pre-wrap',
    }}>{schoolFocusGroupReport.summary}</div>
    <h2>Student response trends and quotes</h2>
    <ol>
      {schoolFocusGroupReport.themes.map(t => (
        <li key={t.id}>
          <h3>{t.name}</h3>
          <h4>Response Trends/Themes</h4>
          <ul>
            {t.takeaways.map(s => (
              <li key={s}>{s}</li>
            ))}
          </ul>
          <h4>Notable student quotes</h4>
          <ul>
            {t.quotes.map(q => {
              console.log(q);

              return <li key={q.id}>
                <Link to={`/school-focus-group/${schoolFocusGroupReport.school_id}/${q.id}?t=${encodeURIComponent(q.text)}`} style={{ marginRight: '8px' }}>"{q.text}"</Link>
                <AuthorTag speakerId={q.speaker_id} shape="chip" />
              </li>;
            })}
          </ul>
        </li>
      ))}
    </ol>
  </div>;
};

export const ToC = ({ files }: { files: FocusGroupFile[] }) => {
  
  // Only show when there are multiple transcripts or notes
  if (files.length <= 1) {
    return null;
  }
  
  return <div>
    <h2>Table of Contents</h2>
    <ol>
      {files?.map((file, i) => <li key={file.data_file_id}>
        <a href={`#transcript-${i}`}>Transcript {i + 1}</a>
      </li>)}
      {files?.map((file, i) => <li key={file.data_file_id}>
        <a href={`#note-${i}`}>Notes {i + 1}</a>
      </li>)}
    </ol>
  </div>;
};

const FocusGroupTab = ({ focusGroup, schoolFocusGroup }: { focusGroup: FocusGroup, schoolFocusGroup: SchoolFocusGroup }) => {

  const [searchParams] = useSearchParams();
  const t = searchParams.get('t');
  const targetRef = useRef<HTMLDivElement>(null);

  if (!focusGroup) {
    return <div>Focus group not found</div>;
  }

  return <Searchable>
    {({ searchInput, search, highlightClassName }) => {
      return <div>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <div style={{ flex: '1' }}>
            <h2>
              {formatDate(focusGroup.focus_group_datetime)}
            </h2>
          </div>
          {searchInput}
        </div>
        <ToC files={focusGroup.files} />
        <h3>Transcript</h3>
        <div ref={targetRef}>
          {focusGroup.files.map((file, i) => {

            return <div key={file.data_file_id}>
              <div id={`transcript-${i}`}>
                <Comments
                  highlightClassName={highlightClassName}
                  highlight={[t ? decodeURIComponent(t) : search]}
                  quotes={file.transcript} />
              </div>
              <Divider />
              <h3>Notes</h3>
              {(file.notes ?? []).map((note) => (
                <div key={note.id}>
                  <h4>
                    <Highlighter
                      highlightClassName={highlightClassName}
                      searchWords={[search]}
                      autoEscape={true}
                      textToHighlight={note.title}
                    />
                  </h4>
                  <div>
                    <Highlighter
                      highlightClassName={highlightClassName}
                      searchWords={[search]}
                      autoEscape={true}
                      textToHighlight={note.text}
                    />
                  </div>
                </div>
              ))}
            </div>;
          })}
        </div>
      </div>;
    }}
  </Searchable>;
};

const FocusGroupContainer = () => {
  const { schoolId, focusGroupId } = useParams();
  const navigate = useNavigate();

  const { schoolFocusGroupReport, loading: loadingSchoolFocusGroupReport } = useSchoolFocusGroupReport({ schoolId: schoolId as string });
  const { schoolFocusGroup, loading: loadingSchoolFocusGroup } = useSchoolFocusGroup({ schoolId: schoolId as string });

  if (loadingSchoolFocusGroupReport || loadingSchoolFocusGroup) {
    return <div>Loading...</div>;
  }

  if (!schoolFocusGroupReport || !schoolFocusGroup) {
    return <div>School not found</div>;
  }

  return <>
    <PageHeader title="Focus Group Transcript and Summary" pageDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."/>
    <div className="main-content">
      <Card>
        <Link to={'/school-focus-groups'}>
          <Button
            endIcon={<ArrowBackIosOutlined />}
            size="small"
          >Back</Button>
        </Link>
        <Tabs value={focusGroupId ? Number.parseInt(focusGroupId) : 0} onChange={(_, t) => {
          if (t === 0) {
            navigate(`/school-focus-group/${schoolId}`);  
          } else {
            navigate(`/school-focus-group/${schoolId}/${t}`);
          }
        }}>
          <Tab label="Summary" />
          {schoolFocusGroup.focus_groups.map((focusGroup, i) => (
            <Tab key={i} label={focusGroup.name} />
          ))}
        </Tabs>
        <div style={{ padding: '24px' }}>
          {focusGroupId ? <FocusGroupTab 
            schoolFocusGroup={schoolFocusGroup}
            focusGroup={schoolFocusGroup.focus_groups[Number.parseInt(focusGroupId) - 1]} /> : <SchoolSummaryTab schoolFocusGroupReport={schoolFocusGroupReport} />}
        </div>
      </Card>
    </div>
  </>;
};

export default FocusGroupContainer;
