import { Avatar, Grid, Chip } from '@mui/material';
import Highlighter from 'react-highlight-words';
import type { Quote } from '../../services/focusGroupService';

export type Author = {
  id: string,
  name: string,
  color?: string,
};

export const stringToColor = (string: string) => {
  let hash = 0;
  let i: number;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

const stringAvatar = (name: string) => {
  const [first, last] = name.split(' ');
  
  return {
    sx: {
      bgcolor: '#145758', //stringToColor(name),
      height: '26px',
      width: '26px',
      fontSize: '14px',
    },
    children: [first, last].filter(Boolean).map((n) => n[0]).join(''),
  };
};

export const AuthorTag = ({ speakerId, shape }: { speakerId: string, shape?: 'circle' | 'chip' }) => {
  
  if (shape === 'circle') {
    return <Avatar {...stringAvatar(speakerId)} style={{ color: '#fff' }}/>;
  }

  return <Chip
    avatar={<Avatar {...stringAvatar(speakerId)} style={{ color: '#fff' }}/>}
    label={speakerId}
    size="small"
  />;
};

const Comments = ({ quotes, highlight, highlightClassName }: { quotes?: Quote[], highlight?: string[], highlightClassName?: string }) => {
  return <div>
    {quotes?.map((quote) => (
      <Grid key={quote.id} container wrap="nowrap" spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item>
          <AuthorTag speakerId={quote.speaker_id} shape="circle" />
        </Grid>
        <Grid justifyContent="left" item xs zeroMinWidth>
          <h4 style={{ margin: '0px 0px 8px 0px', textAlign: 'left' }}>
            <Highlighter
              highlightClassName={highlightClassName}
              searchWords={highlight ?? []}
              autoEscape={true}
              textToHighlight={quote.speaker_id}
            />
          </h4>
          <Highlighter
            highlightClassName={highlightClassName}
            searchWords={highlight ?? []}
            autoEscape={true}
            textToHighlight={quote.text}
          />
        </Grid>
      </Grid>
    ))}
  </div>;
};

export default Comments;