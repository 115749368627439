import { useState } from 'react';
import TranscriptSelectionTables from './TranscriptSelectionTables';
import TranscriptSelectionSideDrawer from './TranscriptSelectionSideDrawer';
//import { withData } from '../reusable/withData';
import PageHeader from '../reusable/PageHeader';
import TranscriptSelectionHeader from './TranscriptSelectionHeader';

import { useTranscriptSelectionOutletContext } from '../hooks/useTranscriptSelectionOutletContext';

import './TranscriptSelection.css';

function TranscriptSelection() {
  const { is_finalized, transcripts } = useTranscriptSelectionOutletContext();

  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(is_finalized);

  return (
    <>
      <PageHeader 
        title={is_finalized? 'Finalized transcripts': 'Transcript selection'}
        pageDescription="Select student transcripts to explore data and gather insights."
      >
      </PageHeader>
      <div className="transcript-selection-side-drawer-tables-container">
        <div className="transcript-selection-header-tables-container main-content">
          <TranscriptSelectionHeader handleSideDrawerOpen={() => setIsSideDrawerOpen(!isSideDrawerOpen)} />
          { Object.keys(transcripts).length !== 0 ?
            <TranscriptSelectionTables isSideDrawerOpen={isSideDrawerOpen}/>
            :
            <p>Missing transcripts data!</p>
          }
        </div>
        { Object.keys(transcripts).length !== 0 ?
          <TranscriptSelectionSideDrawer 
            isOpen={isSideDrawerOpen}
            handleClose={() => setIsSideDrawerOpen(false)}
          />
          :
          null
        }
      </div>
    </>
  );
}

export default TranscriptSelection;

// const TranscriptSelectionWithData = () => {
  
//   const { selectedSchoolFromSideNav } = useSchools();

//   const WrappedComponent = withData({
//     WrappedComponent: TranscriptSelection,
//     fetchData: (eoaId: string, access_token: string) => getTranscriptSelectionSummary(eoaId, access_token, selectedSchoolFromSideNav),
//     errorMessage: 'Error occurred while fetching transcript selection summary data!',
//     options: {
//       dependencies: [selectedSchoolFromSideNav]
//     }
//   });

//   return <WrappedComponent />;
// };

// export default TranscriptSelectionWithData;