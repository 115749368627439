import { NestedUploadRequirementsData, PossibleUploadStates } from '../interfaces/UploadRequirementsInterface';
import SuccessIcon from './assets/data-upload-success.svg';
import WarningIcon from './assets/data-upload-warning-amber.svg';
import EmptyCheckBox from './assets/checkbox-outlined.svg';
import FileTypesValues from '../interfaces/FileType';
import { fileTypes } from '../constants';
import { AxiosResponse } from 'axios';
import LoaderIcon from './assets/data-upload-loader.svg';

import './SchoolsTable.css';

const uploadStatuses = [
  { status: 'Uploaded (no errors)', src: SuccessIcon, alt: 'check mark icon' },
  { status: 'Uploaded (with errors)', src: WarningIcon, alt: 'warning icon' },
  { status: 'Not yet uploaded', src: EmptyCheckBox, alt: 'empty check box icon' },
  { status: 'Data processing', src: LoaderIcon, alt: 'processing icon' },
];

export type SchoolsTableData = PromiseSettledResult<AxiosResponse<NestedUploadRequirementsData>>;
interface SchoolTableProps {
  data: SchoolsTableData;
  fileType: FileTypesValues;
}

export const STUDENT_DEMOGRAPHICS_COLUMNS_NAMES = ['Gender', 'Race/Ethnicity', 'Students with disability', 'English language learners', 'Low income'];

export function SchoolsTable({ data, fileType }: SchoolTableProps) {

  if (data.status === 'rejected') return <p className="center-text">Missing upload requirements data!</p>;
  let schoolsData = data.value.data.schools;

  const getColumnNames = (schoolData: { [key: string]: PossibleUploadStates }) => {
    if (fileType === fileTypes.STUDENT_DEMOGRAPHICS) return STUDENT_DEMOGRAPHICS_COLUMNS_NAMES;

    return Object.keys(schoolData);
  };

  const prepareData = () => {
    let columnNames: string[] = [];
    let tableData: (string)[][] = [];

    Object.entries(schoolsData).forEach(([schoolName, schoolData]) => {
      columnNames = getColumnNames(schoolData);
      tableData.push([schoolName, ...Object.values(schoolData)]);
    });

    return { columnNames, tableData };
  };

  const { columnNames, tableData } = prepareData();

  return (
    <>
      <div className="upload-statuses-container">
        {uploadStatuses.map(({ status, src, alt }, index) => (
          <div key={index} className="upload-status">
            <img src={src} alt={alt} className="icon-size" />
            <div>{status}</div>
          </div>
        ))}
      </div>
      <table className="table">
        <thead className="table-header">
          <tr className="table-row">
            <th>School</th>
            {columnNames.map((columnName, index) => (
              <th key={index}>
                {columnName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="table-body">
          {tableData.map((dataRow, index) => (
            <tr key={index} className="table-row">
              {dataRow.map((dataCell, cellIndex) => {
                return (cellIndex === 0 ?
                  <td className="align-left" key={cellIndex}>{dataCell}</td>
                  :
                  <td key={cellIndex}>
                    {dataCell === PossibleUploadStates.UPLOADED && <img className="icon-size" src={SuccessIcon} alt="check mark icon" />}
                    {dataCell === PossibleUploadStates.NOT_YET_UPLOADED && <img className="icon-size" src={EmptyCheckBox} alt="empty check box icon" />}
                    {dataCell === PossibleUploadStates.UPLOADED_WITH_ERRORS && <img className="icon-size" src={WarningIcon} alt="warning icon" />}
                    {dataCell === PossibleUploadStates.UPLOAD_IN_PROGRESS && <img className="icon-size" src={LoaderIcon} alt="processing icon" />}
                    {dataCell === PossibleUploadStates.NOT_APPLICABLE && <span>N/A</span>}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default SchoolsTable;