import {
  Chart as ChartJS,
  registerables
} from 'chart.js';

import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(...registerables);

interface HorizontalBarChartProps {
  labels: string[];
  dataSets: {data: number[], backgroundColor: string}[]
  borderRadius?: number;
  customAspectRatio?: number;
  customBarPercentage?: number;
  customCategoryPercentage?: number;
  customLayoutPaddingRight?: number;
  dataLabelsFormatter?: (value: string) => void;
}

function HorizontalBarChart({
  labels, 
  dataSets, 
  borderRadius, 
  customAspectRatio, 
  customBarPercentage, 
  customCategoryPercentage, 
  customLayoutPaddingRight, 
  dataLabelsFormatter 
}: HorizontalBarChartProps) {

  const _datasets = dataSets.map(dataSet => {
    return {
      data: dataSet.data,
      backgroundColor: dataSet.backgroundColor,
      borderRadius: borderRadius ? borderRadius : 0,
      borderSkipped: false,
      barPercentage: customBarPercentage || 0.95,
      categoryPercentage: customCategoryPercentage || 1.0
    };
  });

  const data = {
    labels: labels,
    datasets: [..._datasets]
  };

  return (
    <Chart
      plugins={[ChartDataLabels]}
      type="bar"
      options={{
        events: [],
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: customAspectRatio ? customAspectRatio : 2 / 0.9,
        layout: {
          padding: {
            right: customLayoutPaddingRight ? customLayoutPaddingRight : 15,
          }
        },
        plugins: {
          tooltip: {
            enabled: false
          },
          legend: {
            display: false
          },
          datalabels: {
            display: true,
            formatter: dataLabelsFormatter,
            align: 5,
            anchor: 'end',
            color: '#000000',
            font: {
              size: 16
            }
          },
        },
        indexAxis: 'y',
        scales: {
          x: {
            grid: {
              color: '#000000',
            },
            ticks: {
              display: false,
              callback: (value, index) => index === 0 ? ' ' : null
            },
            border: {
              display: false
            },
            beginAtZero: true
          },
          y: {
            grid: {
              display: false
            },
            border: {
              display: false
            },
            ticks: {
              color: '#000000',
              font: {
                size: 16,
              },
              autoSkip: false,
            }
          }
        }
      }}
      data={data}
    />
  );
}

export default HorizontalBarChart;