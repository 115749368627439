import { useOutletContext } from 'react-router-dom';
import { TranscripsCategories, TranscripsCategoriesKeys, SelectedTranscriptsIdsByCategory } from '../../interfaces/TranscriptsInterface';

interface OutletContextValues {
  selectedTranscriptsIdsByCategory: SelectedTranscriptsIdsByCategory;
  setSelectedTranscriptsIdsByCategory: (selectedTranscrips: SelectedTranscriptsIdsByCategory) => void;
  selectedTranscriptId: string;
  setSelectedTranscriptId: (id: string) => void;
  transcripts: TranscripsCategories;
  is_finalized: boolean;
  categoriesOrder: TranscripsCategoriesKeys[];
}

export function useTranscriptSelectionOutletContext() {
  return useOutletContext<OutletContextValues>();
}

export type {
  OutletContextValues
};