import SideDrawer from '../data qc/SideDrawer';
import InfoIcon from '../assets/info-icon.svg';
import CustomDivider from '../reusable/CustomDivider';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SideDrawerOverlay from '../reusable/SideDrawerOverlay';
import TranscriptSelectionDrawerContent from './TranscriptSelectionDrawerContent';

import './TranscriptSelectionSideDrawer.css';

export interface ChartData {
  title: string;
  labels: string[];
  dataSet: {
    data: number[];
    backgroundColor: string;
  };
}

interface TranscriptSelectionSideDrawerProps {
  isOpen: boolean;
  handleClose: () => void;
}

function TranscriptSelectionSideDrawer({ isOpen, handleClose }: TranscriptSelectionSideDrawerProps) {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {isSmallScreen ?
        <SideDrawerOverlay isOpen={isOpen} handleClose={handleClose} anchor="right">
          <div className="transcript-selection-overlay-content">
            <div className="side-drawer-header">
              <p>Demographics match</p>
              <img className="icon-size" src={InfoIcon} alt="overlay info icon" />
            </div>
            <CustomDivider orientation="horizontal" size="medium"/>
            <TranscriptSelectionDrawerContent/>
          </div>
        </SideDrawerOverlay>
        :

        <SideDrawer
          title="Demographics match"
          handleClose={handleClose}
          isOpen={isOpen}
          classes={['transcript-selection-side-drawer']}
          icon={InfoIcon}
        >
          <TranscriptSelectionDrawerContent/>
        </SideDrawer>
      }
    </>
  );
}

export default TranscriptSelectionSideDrawer;