import { useRef, useState } from 'react';

import { IconButton, InputAdornment } from '@mui/material';

import { OutlinedInput } from '@mui/material';
import { useEffect } from 'react';
import { ArrowDownward, ArrowUpward, Search } from '@mui/icons-material';

import './Searchable.css';
const Searchable = ({ children }: { children: ({ searchInput, search, highlightClassName }: { searchInput: React.ReactNode, search: string, highlightClassName: string }) => React.ReactNode }) => {
  
  const searchableIdRef = useRef(`searchable-${new Date().getTime()}`);
  const targetRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState('');
  const [searchIndex, setSearchIndex] = useState(0);
  const [count, setCount] = useState(0);

  const highlightClassName = `highlight-${searchableIdRef.current}`;

  useEffect(() => {
    if (search.length === 0) {
      setCount(0);
      setSearchIndex(0);
      return;
    }

    const allText = targetRef.current?.innerText;
    const matches = (allText?.toLowerCase()?.match(new RegExp(search.toLowerCase(), 'g')) || []);
    setCount(matches.length);
    setSearchIndex(0);
  }, [search]);

  useEffect(() => {
    const allHighlighted = document.querySelectorAll(`.${highlightClassName}`);
    allHighlighted[searchIndex]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    
    for (const el of Array.from(allHighlighted)) {
      el.classList.remove('active-highlight');
    }
    
    allHighlighted[searchIndex]?.classList.add('active-highlight');
  }, [searchIndex, highlightClassName]);

  const searchInput = <OutlinedInput
    value={search}
    size="small"
    style={{ width: '250px' }}
    onChange={(e) => setSearch(e.target.value)}
    startAdornment={
      <InputAdornment position="start">
        <Search />
      </InputAdornment>
    }
    endAdornment={
      count > 0 && (
        <InputAdornment position="end">
          <IconButton
            disabled={searchIndex === 0}
            onClick={() => setSearchIndex(searchIndex - 1)}><ArrowUpward /></IconButton>
          <div>{searchIndex + 1} of {count}</div>
          <IconButton 
            disabled={searchIndex === count - 1}
            onClick={() => setSearchIndex(searchIndex + 1)}><ArrowDownward /></IconButton>
        </InputAdornment>
      )
    }
    aria-describedby="outlined-weight-helper-text"
    inputProps={{
      'aria-label': 'weight',
    }}
  />;

  return <div ref={targetRef}>
    {children({
      highlightClassName,
      searchInput,
      search,
    })}
  </div>;
};

export default Searchable;