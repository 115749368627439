import { useState } from 'react';
import FileUploadStatus from '../reusable/FileUploadStatus';
import CustomPagination from '../reusable/CustomPagination';
import { UploadedFileData } from '../../interfaces/UploadedFileDataInterface';
import FileTypesValues from '../../interfaces/FileType';
import { DATA_UPLOAD_ITEMS_PER_PAGE } from '../../constants';

import './UploadedFilesList.css';

interface UploadedFilesListProps {
  uploadedFilesData: UploadedFileData[];
  handleResubmit: (currentFileId: string) => void;
  uploadProgress: number;
  fileType: FileTypesValues;
}
function UploadedFilesList({ uploadedFilesData, handleResubmit, uploadProgress, fileType }: UploadedFilesListProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const sliceStartIndex = (currentPage - 1) * DATA_UPLOAD_ITEMS_PER_PAGE;
  const sliceEndIndex = currentPage * DATA_UPLOAD_ITEMS_PER_PAGE;

  return (
    <div className="uploaded-files">
      {uploadedFilesData.slice(sliceStartIndex, sliceEndIndex).map((fileStatusData, index) => (
        <FileUploadStatus
          key={index}
          fileId={fileStatusData.file_id}
          fileName={fileStatusData.file_name}
          fileSize=""
          fileStatus={fileStatusData.status}
          fileMetadata={fileStatusData.user_provided_file_metadata}
          results={fileStatusData.results_summary}
          statistics={fileStatusData.statistics}
          onResubmit={handleResubmit}
          fileType={fileType}
          uploadProgress={uploadProgress}
        />
      ))}
      { uploadedFilesData.length >= 10 ?
        <CustomPagination
          currentPage={currentPage}
          totalItems={uploadedFilesData.length}
          pages={Math.ceil(uploadedFilesData.length / DATA_UPLOAD_ITEMS_PER_PAGE)}
          pageSize={DATA_UPLOAD_ITEMS_PER_PAGE}
          handlePageChange={setCurrentPage}
        />
        :
        null
      }
    </div>
  );
}

export default UploadedFilesList;