import { createTheme } from '@mui/material/styles';

const primaryButtonBackgroundColor = 'var(--accent-green-dark)';
const primaryButtonTextColor = 'var(--text-white)';
const primaryButtonHoverBackgroundColor = 'var(--accent-green-dark-hovered)';
const primaryButtonDisabledBackgroundColor = 'var(--background-disabled)';
const primaryButtonDisabledTextColor = 'var(--text-disabled)';

const outlinedButtonBackgroundColor = 'var(--background-white)';
const outlinedButtonTextColor = 'var(--text-accent)';
const outlinedButtonHoverBackgroundColor = 'var(--accent-green-light)';
const outlinedButtonDisabledBackgroundColor = 'var(--background-white)';
const outlinedButtonDisabledTextColor = 'var(--text-disabled)';

const textButtonBackgroundColor = 'transparent';
const textButtonTextColor = 'var(--text-accent)';
const textButtonHoverBackgroundColor = 'var(--accent-green-light)';
const textButtonDisabledBackgroundColor = 'transparent';
const textButtonDisabledTextColor = 'var(--text-disabled)';

const clearButtonBackgroundColor = 'transparent';
const clearButtonTextColor = 'var(--text-black)';
const clearButtonHoverBackgroundColor = 'transparent';
const clearButtonDisabledBackgroundColor = 'transparent';
const clearButtonDisabledTextColor = 'var(--text-disabled)';

const borderColorAccent = 'var(--border-color-accent)';
const borderColorDisabled = 'var(--border-color-accent)';
const borderRadius = 'var(--border-radius-20)';

const buttonBorderAccent = `1px solid ${borderColorAccent}`;

const smallButtonPadding = 'var(--scaling-spacing-8) var(--scaling-spacing-16)';
const mediumButtonPadding = 'var(--scaling-spacing-12) var(--scaling-spacing-24)';

const focusOutlineColor = 'var(--outline-color)';
const focusOutline = `2px solid ${focusOutlineColor}`;
const focusOutlineOffset = '2px';

// const accentColorBlueLight = '#C3ddfd'; // primary-200
// const accentColorBlueDark = '#1E429F'; // primary-800
export const darkGreenBarColor = '#145758';
export const lightGreenBarColor = '#83FBA3';
export const errorIconColor = 'var(--red-dark)';
export const emptyCheckboxIconColor = 'var(--container-background-color)';
export const greenCheckboxIconColor = 'var(--green-dark)';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    'text-underlined': true;
    'clear': true;
  }
}

const XQTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1239,
      lg: 1440,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: 'Roobert, sans-serif',
    button: {
      fontSize: 'var(--font-size-16)',
      fontWeight: 'var(--font-weight-400)',
      lineHeight: 1.25,
      textTransform: 'capitalize',
    }
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: textButtonTextColor,
          backgroundColor: textButtonBackgroundColor,
          padding: 0,
          '&:hover': {
            backgroundColor: textButtonHoverBackgroundColor
          },
          '&.Mui-disabled': {
            backgroundColor: textButtonDisabledBackgroundColor,
            color: textButtonDisabledTextColor
          },
          '&.Mui-focusVisible': {
            backgroundColor: textButtonBackgroundColor,
            outline: focusOutline,
            outlineOffset: focusOutlineOffset
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          borderRadius: borderRadius,
          display: 'flex',
          padding: smallButtonPadding,
          fontSize: 'var(--font-size-14)',
          fontWeight: 400,
          '&.Mui-focusVisible': {
            outline: focusOutline,
            outlineOffset: focusOutlineOffset
          },
          variants: [
            {
              props: { variant: 'contained' },
              style: {
                color: primaryButtonTextColor,
                backgroundColor: primaryButtonBackgroundColor,
                '&:hover': {
                  backgroundColor: primaryButtonHoverBackgroundColor
                },
                '&.Mui-disabled': {
                  backgroundColor: primaryButtonDisabledBackgroundColor,
                  color: primaryButtonDisabledTextColor
                },
              }
            },
            {
              props: { variant: 'outlined' },
              style: {
                color: outlinedButtonTextColor,
                backgroundColor: outlinedButtonBackgroundColor,
                border: buttonBorderAccent,
                '&:hover': {
                  backgroundColor: outlinedButtonHoverBackgroundColor,

                },
                '&.Mui-disabled': {
                  borderColor: borderColorDisabled,
                  backgroundColor: outlinedButtonDisabledBackgroundColor,
                  color: outlinedButtonDisabledTextColor
                }
              }
            },
            {
              props: { variant: 'text' },
              style: {
                color: textButtonTextColor,
                backgroundColor: textButtonBackgroundColor,
                '&:hover': {
                  backgroundColor: textButtonHoverBackgroundColor,

                },
                '&.Mui-disabled': {
                  backgroundColor: textButtonDisabledBackgroundColor,
                  color: textButtonDisabledTextColor
                }
              }
            },
            {
              props: { variant: 'text-underlined' },
              style: {
                color: textButtonTextColor,
                backgroundColor: textButtonBackgroundColor,
                textDecoration: 'underline',
                fontWeight: 600,
                padding: 0,
                '&:hover': {
                  backgroundColor: textButtonBackgroundColor,
                  textDecoration: 'underline'
                },
                '&.Mui-disabled': {
                  backgroundColor: textButtonDisabledBackgroundColor,
                  color: textButtonDisabledTextColor
                }
              }
            },
            {
              props: { variant: 'clear' },
              style: {
                color: clearButtonTextColor,
                backgroundColor: clearButtonBackgroundColor,
                fontWeight: 400,
                '&:hover': {
                  backgroundColor: clearButtonHoverBackgroundColor,
                },
                '&.Mui-disabled': {
                  backgroundColor: clearButtonDisabledBackgroundColor,
                  color: clearButtonDisabledTextColor
                }
              }
            },
            {
              props: { size: 'medium' },
              style: {
                padding: mediumButtonPadding
              }
            }
          ]
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 'var(--scaling-spacing-4)',
          '&.Mui-checked': {
            color: 'var(--accent-green-dark)'
          },
          '&.Mui-checked.Mui-disabled': {
            color: 'var(--divider-10)'
          },
          '&.MuiCheckbox-indeterminate': {
            color: 'var(--accent-green-dark)'
          },
          '&.MuiCheckbox-indeterminate:hover': {
            backgroundColor: 'var(--accent-green-light)'
          },
          '&.Mui-checked:hover': {
            backgroundColor: 'var(--accent-green-light)'
          },
          '&:hover': {
            backgroundColor: 'var(--divider-10)'
          },
          '&.Mui-focusVisible': {
            outline: focusOutline
          },
          '& + .MuiFormControlLabel-label': {
            marginLeft: 'var(--scaling-spacing-4)'
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          width: 'fit-content',
          '&.option': {
            marginLeft: '18px'
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 'var(--border-radius-24)',
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
          marginRight: 'var(--scaling-spacing-8)',
          fontSize: 'var(--font-size-24)',
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 'var(--constant-spacing-24)',
          paddingTop: 0
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& label': {
            color: 'var(--text-gray)',
            '&.Mui-focused': {
              color: 'var(--accent-blue-dark)',
            },
            '&.Mui-error': {
              color: 'var(--error-dark)',
            },
            '&.Mui-disabled': {
              color: 'var(--text-disabled)',
            },
          },
          '& .MuiInputBase-root': {
            '&::before': {
              borderBottom: '1px solid var(--divider-100)',
              '&:hover': {
                borderBottom: '2px solid var(--divider-100)',
              }
            },
            '&.Mui-focused::after': {
              borderBottom: '2px solid var(--accent-blue-dark)',
            },
            '&.Mui-error::after': {
              borderBottom: '1px solid var(--error-dark)',
              '&:hover': {
                borderBottom: '1px solid var(--error-dark)',
              }
            },
            '&.Mui-disabled::before': {
              borderBottom: '1px solid var(--divider-10)',
            },
            '& .MuiSvgIcon-root': {
              color: 'var(--text-black)',
            },
            '&.Mui-error': {
              '& .MuiSvgIcon-root': {
                color: 'var(--error-dark)',
              },
            },
            '&.Mui-disabled': {
              '& .MuiSvgIcon-root': {
                color: 'var(--text-disabled)',
              },
            },
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: 'var(--background-white)',
          borderRadius: '4px',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          padding: 0,
        },
        list: {
          backgroundColor: 'var(--background-white)',
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'var(--background-gray)',
          },
          '&.Mui-selected': {
            backgroundColor: 'var(--accent-blue-light)',
            '&:hover': {
              backgroundColor: 'var(--accent-blue-light)',
            },
          },
        },
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          boxShadow: 'none'
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--accent-green-light)',
          fontWeight: '600',
          borderTop: '2px solid var(--border-color-accent)',
          borderBottom: '2px solid var(--border-color-accent)',
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(even)': {
            backgroundColor: 'var(--background-green)'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: 'var(--text-black)'
        },
        head: {
          fontWeight: 'inherit',
          fontSize: 'var(--font-size-16)'
        },
        body: {
          borderColor: 'var(--border-color)'
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          display: 'flex',
          flexDirection: 'column',
          background: 'var(--background-white)',
          borderRadius: 0
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar1Determinate: {
          backgroundColor: 'var(--accent-green-dark)',
        },
        bar1Indeterminate: {
          backgroundColor: 'var(--accent-green-dark)',
        },
        bar2Indeterminate: {
          backgroundColor: 'var(--accent-green-dark)',
        },
        colorPrimary: {
          backgroundColor: 'var(--divider-10)',
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          '&::before': {
            backgroundColor: 'transparent',
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            padding: '0 var(--constant-spacing-16)',
          }
        },
        content: {
          margin: 'var(--constant-spacing-24) 0',
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0 var(--constant-spacing-16)',
        }
      }
    }
  }
});

export default XQTheme;