import { SelectChangeEvent } from '@mui/material';
import useSchools from '../hooks/useSchools';
import useUserDetails from '../hooks/useUserDetails';
import CustomButton from '../reusable/CustomButton';
import Loader from '../reusable/Loader';
import SelectDropdown from '../reusable/SelectDropdown';
import DownloadIcon from '../assets/download-icon.svg';
import CheckmarkCircleIcon from '../assets/check-icon-circle.svg';
import { useTranscriptSelectionOutletContext } from '../hooks/useTranscriptSelectionOutletContext';
import { useState } from 'react';
import { USER_ROLES, TRANSCRIPTS_CATEGORIES_SELECTION_LIMITS, TRANSCRIPTS_CATEGORIES } from '../../constants';
import TranscriptSelectionWarningModal from './TranscriptSelectionWarningModal';

import './TranscriptSelectionHeader.css';

const selectionLimits = [
  TRANSCRIPTS_CATEGORIES_SELECTION_LIMITS.RIGOROUS_ACADEMIC, 
  TRANSCRIPTS_CATEGORIES_SELECTION_LIMITS.ON_THE_CUSP, 
  TRANSCRIPTS_CATEGORIES_SELECTION_LIMITS.STRUGGLING
];

interface TranscriptSelectionHeaderProps {
  handleSideDrawerOpen: () => void;
};

function TranscriptSelectionHeader ({ handleSideDrawerOpen }: TranscriptSelectionHeaderProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: userDetails } = useUserDetails();
  const { schools: { isPending, error, data: schools }, selectedSchoolFromSideNav, updateSelectedSchool } = useSchools();
  const { is_finalized, selectedTranscriptsIdsByCategory } = useTranscriptSelectionOutletContext();
  const selectionCounts = [
    selectedTranscriptsIdsByCategory[TRANSCRIPTS_CATEGORIES.RIGOROUS_ACADEMIC].length,
    selectedTranscriptsIdsByCategory[TRANSCRIPTS_CATEGORIES.ON_THE_CUSP].length,
    selectedTranscriptsIdsByCategory[TRANSCRIPTS_CATEGORIES.STRUGGLING].length
  ];

  const isCompleteSelectionDisabled = selectionCounts.some((count, index) => selectionLimits[index] !== count);

  const userRole = userDetails?.role;
  const isRestrictedView = userRole === USER_ROLES.XQ_USER || userRole === USER_ROLES.ACCOUNT_MANAGER;

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    updateSelectedSchool(value);
  };

  return (
    <>
      <TranscriptSelectionWarningModal
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
      />
      <div className="transcript-selection-header">
        {isPending ? <Loader /> : null}
        {error ? <p>Missing schools data!</p> : null}
        { schools ?
          <SelectDropdown
            label="School"
            selectedValue={selectedSchoolFromSideNav}
            handleChange={handleChange}
            items={schools ? 
              schools.map((school) => ({
                label: school.name,
                value: school.id
              }))
              : []}
            isClearable={false}
            selectSxProps={{ '&.MuiInputBase-root': { marginTop: 'var(--constant-spacing-8)' } }}
          />
          :
          null
        }
        {is_finalized ? <div className="completed"> <img src={CheckmarkCircleIcon} alt="checkmark icon" /> Selection completed </div> : null}
        <div className="transcript-selection-header-actions">
          <CustomButton
            variant="text"
            buttonText="Download rubric"
            icon={<img src={DownloadIcon} alt="download"></img>}
            iconPosition="start"
            buttonAction={() => { }}
          />
          <CustomButton
            variant="secondary"
            buttonText="View demographics"
            buttonAction={handleSideDrawerOpen}
          />
          {!is_finalized && !isRestrictedView ?
            <CustomButton
              variant="primary"
              buttonText="Complete selections"
              disabled={isCompleteSelectionDisabled}
              buttonAction={() => setIsModalOpen(true)}
            />
            :
            null
          }
        </div>
      </div>
    </>
  );
}

export default TranscriptSelectionHeader;