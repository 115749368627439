import { FlatUploadRequirementsData, NestedUploadRequirementsData } from '../../interfaces/UploadRequirementsInterface';
import { PossibleUploadStates } from '../../interfaces/UploadRequirementsInterface';

const createResultObject = (uploadedSchoolsCount: number, totalSchoolsCount: number) => {
  let state: 'pending' | 'progress' | 'completed' = 'pending';
  let description = `${uploadedSchoolsCount}/${totalSchoolsCount} Schools Data Uploaded`;

  if (uploadedSchoolsCount === 0) description = 'Not Started';
  if (uploadedSchoolsCount > 0 && uploadedSchoolsCount !== totalSchoolsCount) state = 'progress';
  if (uploadedSchoolsCount === totalSchoolsCount) state = 'completed';

  return { state, description };
};

export const calculateFlatDataUploadProgress = (flatData: FlatUploadRequirementsData) => {
  let uploadedSchoolsCount = 0;
  let schoolNames = Object.keys(flatData.schools);

  schoolNames.forEach(schoolName => {
    if (flatData.schools[schoolName] === PossibleUploadStates.UPLOADED) uploadedSchoolsCount++;
  });

  return createResultObject(uploadedSchoolsCount, schoolNames.length);
};

export const calculateNestedDataUploadProgress = (nestedData: NestedUploadRequirementsData) => {
  let uploadedSchoolsCount = 0;
  let schoolNames = Object.keys(nestedData.schools);

  schoolNames.forEach(schoolName => {
    let uploadStatuses = Object.values(nestedData.schools[schoolName]);
    let isAllDataUploaded = uploadStatuses.every(uploadStatus => uploadStatus === PossibleUploadStates.UPLOADED);
    if (isAllDataUploaded) uploadedSchoolsCount++;
  });

  return createResultObject(uploadedSchoolsCount, schoolNames.length);
};