const fileTypes = Object.freeze({
  STUDENT_ROSTER: 'STUDENT_ROSTER',
  COURSE_CATALOG: 'COURSE_CATALOG',
  STUDENT_DEMOGRAPHICS: 'STUDENT_DEMOGRAPHICS',
  STUDENT_TRANSCRIPTS: 'TRANSCRIPT',
  TEST_SCORES: 'STUDENT_TEST_SCORES',
  CTE_PATHWAYS: 'CTE_PATHWAY_CATALOG',
  FOCUS_GROUP_TRANSCRIPTION: 'FOCUS_GROUP_TRANSCRIPTION',
  FOCUS_GROUP_RECORDING: 'FOCUS_GROUP_RECORDING'
});

const fileTypesLabels = Object.freeze({
  [fileTypes.STUDENT_ROSTER]: 'Student Roster',
  [fileTypes.COURSE_CATALOG]: 'Course Catalog',
  [fileTypes.STUDENT_DEMOGRAPHICS]: 'Student Demographics',
  [fileTypes.STUDENT_TRANSCRIPTS]: 'Student Transcripts',
  [fileTypes.TEST_SCORES]: 'Test Scores',
  [fileTypes.CTE_PATHWAYS]: 'CTE Pathways',
  [fileTypes.FOCUS_GROUP_TRANSCRIPTION]: 'Focus Group Transcripts',
  [fileTypes.FOCUS_GROUP_RECORDING]: 'Focus Group Recordings'
});

const SCHOOL_LOCAL_STORAGE_KEY = 'school';
const DISTRICT_LOCAL_STORAGE_KEY = 'district';

const AUDIT_LOG_ITEMS_PER_PAGE = 10;
const DATA_UPLOAD_ITEMS_PER_PAGE = 10;
const DATA_QC_TRANSCRIPTS_ITEMS_PER_PAGE = 10;
const STUDENT_ROSTER_ITEMS_PER_PAGE = 10;

const USER_ROLES = Object.freeze({
  ADMIN: 'ADMIN',
  FACILITATOR: 'FACILITATOR',
  FACILITATOR_MANAGER: 'SCHOOL_FACILITATOR+ACCOUNT_MANAGER',
  ACCOUNT_MANAGER: 'XQ_ACCOUNT_MANAGER', // aka XQ Admin
  DISTRICT_DATA_LEAD: 'DISTRICT_DATA_LEAD',
  XQ_USER: 'XQ_USER'
});

const UPLOAD_SECTIONS_IDS = Object.freeze({
  [fileTypes.STUDENT_ROSTER]: 'studentRoster',
  [fileTypes.COURSE_CATALOG]: 'courseCatalog',
  [fileTypes.STUDENT_DEMOGRAPHICS]: 'studentDemographics',
  [fileTypes.STUDENT_TRANSCRIPTS]: 'studentTranscripts',
  [fileTypes.TEST_SCORES]: 'testScores',
  [fileTypes.CTE_PATHWAYS]: 'ctePathways',
  [fileTypes.FOCUS_GROUP_TRANSCRIPTION]: 'focusGroupTranscription'
});

const NAVIGATION_LINKS_DATA = Object.freeze({
  OVERVIEW: {
    path: '/overview',
    label: 'Overview'
  },
  DATA_UPLOAD: {
    path: '/data-upload',
    label: 'Data Upload'
  },
  DATA_QC: {
    path: '/data-qc',
    label: 'Quality Check'
  },
  TRANSCRIPTS: {
    path: '/transcripts',
    label: 'Transcript Selection'
  },
  FOCUS_GROUPS: {
    path: '/school-focus-groups',
    label: 'Focus Groups'
  },
  FOCUS_GROUP: {
    path: '/school-focus-group/:schoolId/:focusGroupId?',
    label: 'Focus Group'
  },
});

const DEMOGRAPHIC_PERCENTAGES = Object.freeze({
  RACE: {
    PACIFIC_ISLANDER: 'PACIFIC ISLANDER',
    WHITE: 'WHITE',
    ASIAN: 'ASIAN',
    UNKNOWN: 'UNKNOWN',
    AMERICAN_INDIAN: 'AMERICAN INDIAN',
    LATINX: 'LATINX',
    OTHER: 'OTHER',
    TWO_OR_MORE: 'TWO OR MORE',
    BLACK: 'BLACK',
  },
  INCOME: {
    LOW_INCOME: 'LOW-INCOME',
    ABOVE_LOW_INCOME: 'ABOVE LOW-INCOME',
  },
  ENGLISH_LEARNER: {
    NATIVE: 'NATIVE / FLUENT',
    LEARNER: 'ENGLISH LANGUAGE LEARNER',
    OTHER: 'OTHER'
  },
  DISABILITIES: {
    WITHOUT_DISABILITIES: 'WITHOUT DISABILITIES',
    WITH_DISABILITIES: 'WITH DISABILITIES',
  },
});

const TRANSCRIPTS_CATEGORIES = Object.freeze({
  RIGOROUS_ACADEMIC: 'RIGOROUS_ACADEMIC',
  ON_THE_CUSP: 'ON_THE_CUSP',
  STRUGGLING: 'STRUGGLING'
});

const TRANSCRIPTS_CATEGORIES_SELECTION_LIMITS = Object.freeze({
  [TRANSCRIPTS_CATEGORIES.RIGOROUS_ACADEMIC]: 1,
  [TRANSCRIPTS_CATEGORIES.ON_THE_CUSP]: 6,
  [TRANSCRIPTS_CATEGORIES.STRUGGLING]: 1
});

const TRANSCRIPTS_CATEGORIES_LABELS = Object.freeze({
  [TRANSCRIPTS_CATEGORIES.RIGOROUS_ACADEMIC]: 'High fliers',
  [TRANSCRIPTS_CATEGORIES.ON_THE_CUSP]: 'On the cusp',
  [TRANSCRIPTS_CATEGORIES.STRUGGLING]: 'Struggling'
});

export {
  fileTypes,
  SCHOOL_LOCAL_STORAGE_KEY,
  DISTRICT_LOCAL_STORAGE_KEY,
  AUDIT_LOG_ITEMS_PER_PAGE,
  DATA_UPLOAD_ITEMS_PER_PAGE,
  DATA_QC_TRANSCRIPTS_ITEMS_PER_PAGE,
  STUDENT_ROSTER_ITEMS_PER_PAGE,
  TRANSCRIPTS_CATEGORIES_SELECTION_LIMITS,
  USER_ROLES,
  UPLOAD_SECTIONS_IDS,
  NAVIGATION_LINKS_DATA,
  DEMOGRAPHIC_PERCENTAGES,
  fileTypesLabels,
  TRANSCRIPTS_CATEGORIES,
  TRANSCRIPTS_CATEGORIES_LABELS
};