import { AxiosError } from 'axios';
import { 
  ChartCategoryTypes, 
  Transcript, 
  SubjectArea, 
  DemographicChartLabels, 
  SelectedTranscriptsIdsByCategory,
  TranscripsCategoriesKeys
} from '../../interfaces/TranscriptsInterface';
import { UserDetails } from '../../interfaces/UserDetailsInterface';
import { saveSingleSelection, unsaveSingleSelection } from '../../services/transcriptsService';
import { DemographicsSummary } from '../../interfaces/DataQcInterface';

const chartCategories = [
  ChartCategoryTypes.RACE,
  ChartCategoryTypes.GENDER,
  ChartCategoryTypes.STUDENT_WITH_DISABILITIES,
  ChartCategoryTypes.ENGLISH_LEARNER,
  ChartCategoryTypes.LOW_INCOME
];

type categoriesWithValues = { [key in ChartCategoryTypes]: { [key: string]: number } };

export const getCategoryCountsByType = (data: Transcript[]) => {
  const categoryCountsByType: categoriesWithValues = {
    [ChartCategoryTypes.RACE]: {},
    [ChartCategoryTypes.GENDER]: {},
    [ChartCategoryTypes.STUDENT_WITH_DISABILITIES]: {},
    [ChartCategoryTypes.ENGLISH_LEARNER]: {},
    [ChartCategoryTypes.LOW_INCOME]: {}
  };

  data.forEach(dataItem => {
    chartCategories.forEach((chartCategory) => {
      let currentCategoryData = categoryCountsByType[chartCategory];
      let subCategoryValue = dataItem[chartCategory];

      if (currentCategoryData.hasOwnProperty(subCategoryValue)) {
        currentCategoryData[subCategoryValue]++;
      } else {
        currentCategoryData[subCategoryValue] = 1;
      }
    });
  });

  return categoryCountsByType;
};

export const calcSelectedTranscriptsDemographicPercentages = (
  demographicsSummary: DemographicsSummary | undefined,
  selectedTranscripts: Transcript[]
) => {
  const totalStudents = demographicsSummary?.total?.student_count;
  const totalDemographicsPercentages = demographicsSummary?.total?.demographic_percentages;
  const selectedCount = getCategoryCountsByType(selectedTranscripts);

  const selectedTranscriptsDemographicPercentages: categoriesWithValues = {
    [ChartCategoryTypes.RACE]: {},
    [ChartCategoryTypes.GENDER]: {},
    [ChartCategoryTypes.STUDENT_WITH_DISABILITIES]: {},
    [ChartCategoryTypes.ENGLISH_LEARNER]: {},
    [ChartCategoryTypes.LOW_INCOME]: {}
  };

  const ignoreSubcategories = ['WITHOUT DISABILITIES', 'NATIVE / FLUENT', 'ABOVE LOW-INCOME'];

  for (const categoryType in totalDemographicsPercentages) {
    const subCategoriesObject = totalDemographicsPercentages[categoryType as ChartCategoryTypes];
    const selectedSubCategoriesObject = selectedCount[categoryType as ChartCategoryTypes] || {};

    for (const key in subCategoriesObject) {
      if (!ignoreSubcategories.includes(key)) {
        const totalSubCategoryCount = totalStudents ? (subCategoriesObject[key] * totalStudents) / 100 : 0;
        const selectedSubCategoryCount = selectedSubCategoriesObject[key] || 0;
  
        if (totalSubCategoryCount > 0) {
          let formattedPercentage = Number(((selectedSubCategoryCount / totalSubCategoryCount) * 100).toFixed(1));
          selectedTranscriptsDemographicPercentages[categoryType as ChartCategoryTypes][key] = formattedPercentage;
        }
      }
    }
  }

  return selectedTranscriptsDemographicPercentages;
};

type DemographicCategoriesData = DemographicsSummary['total']['demographic_percentages'];
type SubCategoriesKeys = typeof ChartCategoryTypes['LOW_INCOME'] | typeof ChartCategoryTypes['ENGLISH_LEARNER'] | typeof ChartCategoryTypes['STUDENT_WITH_DISABILITIES'];
type SubCategoriesForChart = { [key in SubCategoriesKeys]: string };

export const prepareDemographicData = (data: DemographicCategoriesData) => {
  const subCategoriesForChart: SubCategoriesForChart = {
    [ChartCategoryTypes.LOW_INCOME]: 'LOW-INCOME',
    [ChartCategoryTypes.ENGLISH_LEARNER]: 'ENGLISH LANGUAGE LEARNER',
    [ChartCategoryTypes.STUDENT_WITH_DISABILITIES]: 'WITH DISABILITIES'
  };

  (Object.keys(subCategoriesForChart) as SubCategoriesKeys[]).forEach(category => {
    let subCategory = subCategoriesForChart[category];
    data[category] = { [subCategory]: data[category][subCategory] };
  });

  return data;
};

export const extractChartData = (categoryData: { [key: string]: number }) => {
  const labels: string[] = [];
  const data: number[] = [];

  Object.entries(categoryData).forEach(([key, value]) => {
    const label = DemographicChartLabels[key as keyof typeof DemographicChartLabels];
    labels.push(label);
    data.push(value);
  });

  return { labels, data };
};

export const buildChartData = (data: DemographicCategoriesData | undefined, chartCategories:  ChartCategoryTypes[], barColor: string) => {
  let combinedLabels: string[] = [];
  let combinedData: number[] = [];

  if (!data) {
    return { labels: [], dataSet: { data: [], backgroundColor: barColor } };
  }

  chartCategories.forEach(chartCategory => {
    let { labels, data: extractedData } = extractChartData(data[chartCategory]);
    combinedLabels = combinedLabels.concat(labels);
    combinedData = combinedData.concat(extractedData);
  });

  return { labels: combinedLabels, dataSet: { data: combinedData, backgroundColor: barColor } };
};

export const createChartConfigs = (data: DemographicCategoriesData, barColor: string) => {
  let raceChartData = buildChartData(data, [ChartCategoryTypes.RACE], barColor);
  let genderChartData = buildChartData(data, [ChartCategoryTypes.GENDER], barColor);
  let miscChartData = buildChartData(
    data,
    [
      ChartCategoryTypes.STUDENT_WITH_DISABILITIES,
      ChartCategoryTypes.ENGLISH_LEARNER,
      ChartCategoryTypes.LOW_INCOME
    ],
    barColor
  );

  let raceChartConfig = {
    title: 'Race/Ethnicity',
    labels: raceChartData.labels,
    dataSet: raceChartData.dataSet
  };

  let genderChartConfig = {
    title: 'Gender',
    labels: genderChartData.labels,
    dataSet: genderChartData.dataSet
  };

  let miscChartConfig = {
    title: 'Students with disabilities, English language learners, Low income students',
    labels: miscChartData.labels,
    dataSet: miscChartData.dataSet
  };

  return [raceChartConfig, genderChartConfig, miscChartConfig];
};

export const formatHelperTextCount = (count: number, itemType: string) => {return `${count} ${count === 1 ? itemType : `${itemType}s`}`;};

export function prepareTranscriptDetailsData(terms: any[]) {

  const tableHeaderData: { key: string; label: string }[] = [];
  
  const subjects = new Map<string, number>(); 
  let creditsPerGrade = 0;

  terms.forEach((term) => {

    term.subject_areas.forEach((subjectArea: SubjectArea) => {
        
      subjects.set(
        subjectArea.name,
        (subjects.get(subjectArea.name) || 0) + subjectArea.num_credits
      );

      creditsPerGrade +=  subjectArea.num_credits;  
    });
  });
  
  subjects.forEach((key, value) => {
    tableHeaderData.push({ key: value, label: `${value} ${formatHelperTextCount(key, 'credit')}` });
    tableHeaderData.push({ key: `${value}-grade`, label: '' });
  });

  return { tableHeaderData, creditsPerGrade };
}

type OnErrorCallBack = (errorMessage: string, error: Error | AxiosError) => void;

export const selectTranscript = async (
  userDetails: UserDetails | undefined,
  category: TranscripsCategoriesKeys, 
  transcriptId: string, 
  currentlySelectedTranscriptsIds: SelectedTranscriptsIdsByCategory,
  isSelected: boolean,
  schoolId: string,
  onError: OnErrorCallBack,
  updateSelectedTranscriptIds: (newIds: SelectedTranscriptsIdsByCategory) => void
) => {
  if (!userDetails) {
    let error = new Error('Missing user details!');
    onError('Missing user details!', error as AxiosError);

    return;
  }

  const paylaod = {category: category, transcript_id: transcriptId};
  const selectedTranscriptsIdsForCategory = currentlySelectedTranscriptsIds[category];
  const updatedSelectedTranscriptsIds = selectedTranscriptsIdsForCategory.filter(id => id !== transcriptId);

  if (isSelected) {
    try {
      currentlySelectedTranscriptsIds[category] = updatedSelectedTranscriptsIds;
      updateSelectedTranscriptIds({...currentlySelectedTranscriptsIds});

      await unsaveSingleSelection(userDetails.eoaId, userDetails.access_token, schoolId, paylaod);
    } catch (error) {
      console.log(error);
      onError('Error occured while unsaving a selected transcript!', error as AxiosError);

      currentlySelectedTranscriptsIds[category] = selectedTranscriptsIdsForCategory;
      updateSelectedTranscriptIds({...currentlySelectedTranscriptsIds});
    }

    return;
  }

  try {
    updatedSelectedTranscriptsIds.push(transcriptId);
    currentlySelectedTranscriptsIds[category] = updatedSelectedTranscriptsIds;
    updateSelectedTranscriptIds({...currentlySelectedTranscriptsIds});

    await saveSingleSelection(userDetails.eoaId, userDetails.access_token, schoolId, paylaod);
  } catch (error) {
    console.log(error);
    onError('Error occured while saving a selected transcript!', error as AxiosError);

    currentlySelectedTranscriptsIds[category] = selectedTranscriptsIdsForCategory;
    updateSelectedTranscriptIds({...currentlySelectedTranscriptsIds});
  }
};

export interface CategorizedData {
  rigorous_academic_transcript_ids: string[];
  on_the_cusp_transcript_ids: string[];
  struggling_transcript_ids: string[];
};

export const prepareCategorizedData = (selectedTranscriptsIdsByCategory: SelectedTranscriptsIdsByCategory) => {
  const categorizedData: CategorizedData = {
    rigorous_academic_transcript_ids: [],
    on_the_cusp_transcript_ids: [],
    struggling_transcript_ids: []
  };

  (Object.keys(selectedTranscriptsIdsByCategory) as TranscripsCategoriesKeys[]).forEach((category) => {
    const key = `${category.toLowerCase()}_transcript_ids`;
    categorizedData[key as keyof typeof categorizedData] = selectedTranscriptsIdsByCategory[category];
  });

  return categorizedData;
};