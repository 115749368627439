import { createContext } from 'react';
import GlobalState from '../interfaces/GlobalStateInterface';

const GlobalStateContext = createContext<GlobalState>({ 
  globalState: {
    userDetails: {
      isPending: true,
      error: null,
      data: undefined
    },
    schools: {
      isPending: true,
      error: null,
      data: undefined
    },
    selectedSchoolFromSideNav: ''
  },
  updateSelectedSchool: () => {}
});

export default GlobalStateContext;