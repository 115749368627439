import { AxiosError } from 'axios';
import { finalizeTranscriptSelection } from '../../services/transcriptsService';
import useErrorModal from '../hooks/useErrorModal';
import useSchools from '../hooks/useSchools';
import { useTranscriptSelectionOutletContext } from '../hooks/useTranscriptSelectionOutletContext';
import useUserDetails from '../hooks/useUserDetails';
import CustomButton from '../reusable/CustomButton';
import CustomModal from '../reusable/CustomModal';
import { prepareCategorizedData } from './utilityFunctions';

import './TranscriptSelectionWarningModal.css';

interface TranscriptSelectionWarningModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

function TranscriptSelectionWarningModal({ isOpen, handleClose }: TranscriptSelectionWarningModalProps) {

  const { data: userDetails } = useUserDetails();
  const { selectedSchoolFromSideNav } = useSchools();
  const { handleOpen: handleOpenErrorModal } = useErrorModal();
  const {selectedTranscriptsIdsByCategory} = useTranscriptSelectionOutletContext();

  const handleFinalizeTranscriptSelection = async () => {
    const categorizedData = prepareCategorizedData(selectedTranscriptsIdsByCategory);
    if (userDetails) {
      try {
        await finalizeTranscriptSelection(userDetails.eoaId, userDetails.access_token, selectedSchoolFromSideNav, categorizedData);
        handleClose();
      } catch (error) {
        console.log(error);
        handleOpenErrorModal('Error occured while finalizing transcript selection!', error as AxiosError);
      }
    }
  };

  return (
    <>
      {isOpen &&
        <CustomModal 
          title="Confirm your selections?" 
          isOpen={isOpen} 
          handleClose={handleClose} 
          icon={false}
        >
          <p>By confirming, you'll finalize these choices. If you want to make further changes, you'll need to contact the lead facilitator.</p>
          <div className="custom-modal-actions-container">
            <CustomButton variant="secondary" buttonText="Keep editing" buttonAction={handleClose} />
            <CustomButton variant="primary" buttonText="Confirm" buttonAction={handleFinalizeTranscriptSelection} />
          </div>
        </CustomModal>
      }
    </>
  );
}

export default TranscriptSelectionWarningModal;