import axios from 'axios';
import { FlatUploadRequirementsData, NestedUploadRequirementsData } from '../interfaces/UploadRequirementsInterface';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const getRostersUploadRequirements = async (eoaId: string, access_token: string) => {
  return await axios.get<FlatUploadRequirementsData>(`${BASE_URL}/eoa/${eoaId}/upload-requirements/rosters`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

const getCourseCatalogUploadRequirements = async (eoaId: string, access_token: string) => {
  return await axios.get<NestedUploadRequirementsData>(`${BASE_URL}/eoa/${eoaId}/upload-requirements/course-catalogs`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

const getStudentDemographicsUploadRequirements = async (eoaId: string, access_token: string) => {
  return await axios.get<NestedUploadRequirementsData>(`${BASE_URL}/eoa/${eoaId}/upload-requirements/demographics`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

const getStudentTranscriptsUploadRequirements = async (eoaId: string, access_token: string) => {
  return await axios.get<FlatUploadRequirementsData>(`${BASE_URL}/eoa/${eoaId}/upload-requirements/transcripts`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

const getTestScoresUploadRequirements = async (eoaId: string, access_token: string) => {
  return await axios.get<NestedUploadRequirementsData>(`${BASE_URL}/eoa/${eoaId}/upload-requirements/test-scores`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

const getCTEPathwaysUploadRequirements = async (eoaId: string, access_token: string) => {
  return await axios.get<NestedUploadRequirementsData>(`${BASE_URL}/eoa/${eoaId}/upload-requirements/cte-pathways`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

const getUploadRequirementsData = async (eoaId: string, access_token: string) => {
  const paths = [
    `${BASE_URL}/eoa/${eoaId}/upload-requirements/rosters`,
    `${BASE_URL}/eoa/${eoaId}/upload-requirements/course-catalogs`,
    `${BASE_URL}/eoa/${eoaId}/upload-requirements/demographics`,
    `${BASE_URL}/eoa/${eoaId}/upload-requirements/transcripts`,
    `${BASE_URL}/eoa/${eoaId}/upload-requirements/test-scores`,
    `${BASE_URL}/eoa/${eoaId}/upload-requirements/cte-pathways`
  ];

  const requests = paths.map(path => 
    axios.get(path, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })
  );

  return await Promise.allSettled(requests);
};

export {
  getRostersUploadRequirements,
  getCourseCatalogUploadRequirements,
  getStudentDemographicsUploadRequirements,
  getStudentTranscriptsUploadRequirements,
  getTestScoresUploadRequirements,
  getCTEPathwaysUploadRequirements,
  getUploadRequirementsData
};