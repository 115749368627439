interface ResultSummary {
  description: string;
  status: string;
  missingColumns: string[];
}

enum UploadedFileStatus {
  UPLOAD = 'UPLOAD',
  SUCCESS = 'SUCCESS',
  PROCESSING = 'PROCESSING',
  DATA_ERRORS = 'DATA_ERRORS',
  MALFORMED = 'MALFORMED',
  UPLOAD_FAILED = 'UPLOAD_FAILED'
}

interface UploadedFileData {
  file_id: string;
  user_provided_file_metadata: {
    [key: string]: string[] | null;
  };
  file_name: string;
  results_summary: ResultSummary[];
  statistics: string[];
  status: keyof typeof UploadedFileStatus;
  upload_file_type: string;
}

interface FileUploadResult {
  description: string;
  status: string;
}

interface AuditLogData {
  [key: string]: string;
  id: string;
  timestamp: string;
  user_id: string;
  user_name: string;
  action: string;
  object_urn: string;
  result: 'SUCCESS' | 'FAILURE';
  file_name: string;
}

interface AuditLog {
  items: AuditLogData[],
  total: number,
  page: number,
  size: number,
  pages: number,
  links: {
    first: string | null,
    last: string | null,
    self: string | null,
    next: string | null,
    prev: string | null
  }
}

export type {
  UploadedFileData,
  FileUploadResult,
  AuditLogData,
  AuditLog,
};

export {
  UploadedFileStatus
};