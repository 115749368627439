import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, TableCell, TableRow } from '@mui/material';
import ArrowDown from '../../assets/data-upload-arrow-down.svg';
import Tag from '../../reusable/Tag';
import CustomDivider from '../../reusable/CustomDivider';
import CustomTable from '../../reusable/CustomTable';
import { Course, Term } from '../../../interfaces/TranscriptsInterface';

import { formatHelperTextCount, prepareTranscriptDetailsData } from '../utilityFunctions';
import './TranscriptDetailsAccordion.css';

interface TranscriptDetailsAccordionProps {
    accordionId: string;
    title: string;
    terms: Term[];
}

const renderTableCellData = (course: Course | undefined, isGradeColumn: boolean) => {

  //render empty column(cell) if there is no course data and grade for the respective columns in the table header
  if (!course) return <></>;

  const { letter_grade, name, code, num_credits } = course;

  //render letter grade for grade columns in the table header
  if (isGradeColumn) return <p className="letter-grade">{letter_grade || ''}</p>;

  //render course data for the respective subject in the table header
  return <div className="course-data">
    <p>{name || ''}</p>
    <p>{`(${code})` || ''}</p>
    <p>{formatHelperTextCount(num_credits, 'credit') || ''}</p>
  </div>;
};

const renderCourseRows = (term: Term, tableHeaderData: { key: any; }[]) => {

  const rows = [];
  
  let maxCoursesCount = 0;
  //find the biggest number of courses in any of the subjects for each term
  term.subject_areas.forEach(subjectArea => {
    maxCoursesCount = Math.max(maxCoursesCount, subjectArea.courses.length);
  });
  
  //render rows with count equal to the biggest number of courses in any of the subjects
  //each row is containting the n-th course data for each subject in its columns (cells)
  //any missing courses and their grades will be rendered as empty columns(cells) for the respective subject and grade columns in the table header
  for (let i = 0; i < maxCoursesCount; i++) {
    rows.push(
      <TableRow key={i}>
        {tableHeaderData.map(({ key }) => {
          //table header consists of subject and grade columns, grade columns have '-grade' suffix and empty label for the table header
          const subjectKey = key.split('-')[0];
          const isGradeColumn = key.includes('-grade');

          //find a subject from the response that matches a subject from the table header
          const subjectArea = term.subject_areas.find(subjectArea => subjectArea.name === subjectKey);
          const course = subjectArea?.courses[i];

          return (
            <TableCell key={key}>
              {renderTableCellData(course, isGradeColumn)}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }
  
  return rows;
};

function TranscriptDetailsAccordion ({ accordionId, title, terms }: TranscriptDetailsAccordionProps) {

  const { tableHeaderData, creditsPerGrade } = prepareTranscriptDetailsData(terms);
  return (
    <div className="transcript-details-accordion">
      <Accordion id={accordionId} defaultExpanded>
        <AccordionSummary
          expandIcon={<img alt="expand accordion icon" src={ArrowDown} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className="transcript-details-accordion-header">
            <h2>{title}</h2>
            <Tag type="info" text={`${creditsPerGrade} credits`} size="medium" className="upload-progress-info" />
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{padding: 0}}>
          <CustomTable tableHeaderData={tableHeaderData}>
            {terms.map((term, termIndex) => (
              <React.Fragment key={termIndex}>
                <TableRow 
                  sx={{'&:nth-of-type(even)': {backgroundColor: 'var(--accent-green-dark)'}}} 
                  className="term-row">
                  <TableCell colSpan={tableHeaderData.length}>
                    <p>{term.name}</p>
                  </TableCell>
                </TableRow>
                {renderCourseRows(term, tableHeaderData)}
              </React.Fragment>
            ))}

          </CustomTable>
        </AccordionDetails>
      </Accordion>
      <CustomDivider orientation="horizontal" size="medium" className="light" />
    </div>
  );
};

export default TranscriptDetailsAccordion;