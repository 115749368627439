
import useSchools from '../../hooks/useSchools';
import useFetchData from '../../hooks/useFetchData';
import TranscriptDetailsAccordion from './TranscriptDetailsAccordion';
import Loader from '../../reusable/Loader';
import { getTranscriptDetails } from '../../../services/transcriptsService';
import TranscriptDetailsHeader from './TranscriptDetailsHeader';
import CustomButton from '../../reusable/CustomButton';
import { useTranscriptSelectionOutletContext } from '../../hooks/useTranscriptSelectionOutletContext';
import { TranscripsCategoriesKeys } from '../../../interfaces/TranscriptsInterface';

import './TranscriptDetails.css';

function TranscriptDetails () {
  const { selectedSchoolFromSideNav } = useSchools();
  const { transcripts, selectedTranscriptId, setSelectedTranscriptId } = useTranscriptSelectionOutletContext();

  const selectedTranscriptData = selectedTranscriptId.split('-');
  const categoryName = selectedTranscriptData[0] as TranscripsCategoriesKeys;
  const transcriptIndex = Number(selectedTranscriptData[1]);
  const previewTranscript = transcripts[categoryName as TranscripsCategoriesKeys][Number(transcriptIndex)];
  let isFirstTranscriptInCategory = transcriptIndex === 0;
  let isLastTranscriptInCategory = transcriptIndex === transcripts[categoryName].length - 1;

  const { isPending, error, response } = useFetchData({
    fetchData: (eoaId: string, access_token: string) => getTranscriptDetails(eoaId, access_token, selectedSchoolFromSideNav, previewTranscript.id),
    errorMessage: '',
    options: {
      dependencies: [previewTranscript.id]
    }
  });

  if (isPending) return <Loader/>;
  if (error) return <p>{error.message}</p>;

  const handleNavigateToPrevTranscript = () => {
    if (!isFirstTranscriptInCategory) setSelectedTranscriptId(`${categoryName}-${transcriptIndex-1}`);
  };

  const handleNavigateToNextTranscript = () => {
    if (!isLastTranscriptInCategory) setSelectedTranscriptId(`${categoryName}-${transcriptIndex + 1}`);
  };

  return (
    <>
      <TranscriptDetailsHeader data={response?.data} category={categoryName} transcript={previewTranscript} />
      <div className="transcript-details-content main-content">
        {response?.data.academic_periods.map(({period, terms}) => 
          (<TranscriptDetailsAccordion 
            key={response?.data.id} 
            accordionId={response?.data.id} 
            title={period} 
            terms={terms}/>
          ))
        }
      </div>
      <div className="bottom-page-buttons">
        { !isFirstTranscriptInCategory ?
          <CustomButton
            variant="secondary"
            buttonText="< Previous Transcript"
            buttonAction={handleNavigateToPrevTranscript}
          />
          :
          null
        }
        { !isLastTranscriptInCategory ?
          <CustomButton
            variant="primary"
            buttonText="Next Transcript >"
            buttonAction={handleNavigateToNextTranscript}
            sx={{ marginLeft: 'auto' }}
          />
          :
          null
        }
      </div>
    </>
  );
};

export default TranscriptDetails;