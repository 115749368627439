import axios, { AxiosProgressEvent } from 'axios';
import Schools from '../interfaces/SchoolsInterface';
import { UploadedFileData } from '../interfaces/UploadedFileDataInterface';
// import { TestTypesResponse } from '../interfaces/TestTypesResponseInterface';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const getSchools = async (eoaId: string, access_token: string) => {
  return await axios.get<Schools[]>(`${BASE_URL}/eoa/${eoaId}/schools`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

const getAcademicYears = async (eoaId: string, access_token: string) => {
  return await axios.get<string[]>(`${BASE_URL}/eoa/${eoaId}/academic-periods`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

const getDemographicCategories = async (eoaId: string, access_token: string) => {
  return await axios.get<string[]>(`${BASE_URL}/eoa/${eoaId}/demographic-categories`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

// const getTestTypes = async (eoaId: string, access_token: string) => {
//   return await axios.get<TestTypesResponse[]>(`${BASE_URL}/eoa/${eoaId}/test-types`, {
//     headers: {
//       Authorization: `Bearer ${access_token}`
//     }
//   });
// };

const getUploadResults = async (eoaId: string, access_token: string) => {
  return await axios.get<UploadedFileData[]>(`${BASE_URL}/eoa/${eoaId}/upload-results`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

interface RequestData {
  file_name: string;
  user_provided_file_metadata: {
    [key: string]: string[] | string;
  };
  upload_file_type: string;
  replaces_file_id?: string;
}
interface Response {
  presigned_url: string;
  s3_key: string
}

const postRequestUploadUrl = async (eoaId: string, access_token: string, requestData: RequestData) => {
  return await axios.post<Response>(`${BASE_URL}/eoa/${eoaId}/request-upload-url`, requestData, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

const uploadFileToS3Bucket = async (presignedUrl: string, fileData: File, uploadProgressHandler: (event: AxiosProgressEvent) => void) => {
  return await axios.put(presignedUrl, fileData, {
    headers: {
      'Content-Type': '',
    },
    onUploadProgress: uploadProgressHandler,
  });
};

export {
  getSchools,
  getAcademicYears,
  getDemographicCategories,
  // getTestTypes,
  getUploadResults,
  postRequestUploadUrl,
  uploadFileToS3Bucket
};