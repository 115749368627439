enum PossibleUploadStates { 
  UPLOADED = 'UPLOADED', 
  UPLOAD_IN_PROGRESS = 'UPLOAD_IN_PROGRESS', 
  UPLOADED_WITH_ERRORS = 'UPLOADED_WITH_ERRORS', 
  NOT_YET_UPLOADED = 'NOT_YET_UPLOADED', 
  NOT_APPLICABLE = 'NOT_APPLICABLE'
};

interface FlatUploadRequirementsData {
  schools: {
    [key: string]: PossibleUploadStates;
  }
}

interface NestedUploadRequirementsData {
  schools: {
    [key: string]: {
      [key: string]: PossibleUploadStates
    }
  }
}

export type {
  FlatUploadRequirementsData,
  NestedUploadRequirementsData,
};

export {
  PossibleUploadStates
};