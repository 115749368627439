import { TRANSCRIPTS_CATEGORIES } from '../constants';

interface TranscriptSelectionSummary {
  is_finalized: boolean,
  transcripts: {
    ON_THE_CUSP: Transcript[],
    RIGOROUS_ACADEMIC: Transcript[],
    STRUGGLING: Transcript[],
  };
}
interface Transcript {
  id: string,
  category: string,
  is_selected: boolean,
  gpa: number,
  credits: number,
  race: string,
  gender: string,
  student_with_disabilities: string,
  english_learner: string,
  low_income: string,
}

interface Course {
  name: string;
  code: string;
  letter_grade: string | null;
  number_grade: number | null;
  num_credits: number;
}

interface SubjectArea {
  name: string;
  num_credits: number;
  courses: Course[];
}

interface Term {
  name: string;
  subject_areas: SubjectArea[]
}

interface AcademicPeriod {
  period: string;
  terms: Term[]
}

interface TranscriptDetails {
  id: string;
  category: string;
  is_selected: boolean;
  gpa: number;
  credits: number;
  race: string;
  gender: string;
  disability_status: string;
  english_learner: string;
  low_income: string;
  academic_periods: AcademicPeriod[];
}

interface Category {
  name: string;
  label: string;
}

type TranscripsCategoriesKeys = keyof typeof TRANSCRIPTS_CATEGORIES;
type TranscripsCategories = {
  [key in TranscripsCategoriesKeys]: Transcript[]
};
type SelectedTranscriptsIdsByCategory = {
  [key in TranscripsCategoriesKeys]: string[]
};

export enum ChartCategoryTypes {
  RACE = 'race',
  GENDER = 'gender',
  STUDENT_WITH_DISABILITIES = 'student_with_disabilities',
  ENGLISH_LEARNER = 'english_learner',
  LOW_INCOME = 'low_income'
}

export enum DemographicChartLabels {
  LATINX = 'Latinx',
  WHITE = 'White',
  'AMERICAN INDIAN' = 'American Indian',
  'PACIFIC ISLANDER' = 'Pacific Islander',
  BLACK = 'Black',
  OTHER = 'Other',
  ASIAN = 'Asian',
  UNKNOWN = 'Unknown',
  'TWO OR MORE' ='Two or more',
  MALE = 'Male',
  FEMALE = 'Female',
  'WITH DISABILITIES' = 'Students with disabilities',
  'ENGLISH LANGUAGE LEARNER' = 'English language learners',
  'LOW-INCOME' = 'Low income students'
}

export type {
  TranscriptSelectionSummary,
  Transcript,
  TranscriptDetails,
  Category,
  Term,
  AcademicPeriod,
  SubjectArea,
  Course,
  TranscripsCategories,
  TranscripsCategoriesKeys,
  SelectedTranscriptsIdsByCategory
};
