import React, { useMemo } from 'react';
import { darkGreenBarColor, lightGreenBarColor } from '../../theme/xq-custom-theme';
import CustomDivider from '../reusable/CustomDivider';
import HorizontalBarChart from '../reusable/HorizontalBarChart';
import Loader from '../reusable/Loader';
import { calcSelectedTranscriptsDemographicPercentages, createChartConfigs, prepareDemographicData } from './utilityFunctions';
import { useTranscriptSelectionOutletContext } from '../hooks/useTranscriptSelectionOutletContext';
import useFetchData from '../hooks/useFetchData';
import { getDemographicsSummary } from '../../services/dataQcSummaryService';
import useSchools from '../hooks/useSchools';

function TranscriptSelectionDrawerContent () {

  const { selectedTranscriptsIdsByCategory, transcripts } = useTranscriptSelectionOutletContext();
  const { selectedSchoolFromSideNav } = useSchools();

  const { isPending, error, response: demographicsResponse } = useFetchData({
    fetchData: (eoaId: string, access_token: string) => getDemographicsSummary(eoaId, access_token, selectedSchoolFromSideNav),
    errorMessage: 'Error occurred while fetching transcript selection demographics data!',
    options: {
      dependencies: [selectedSchoolFromSideNav]
    }
  });

  const allTrascripts = transcripts.RIGOROUS_ACADEMIC.concat(transcripts.ON_THE_CUSP).concat(transcripts.STRUGGLING);
  const allSelectedTrascriptsIds = selectedTranscriptsIdsByCategory.RIGOROUS_ACADEMIC.concat(selectedTranscriptsIdsByCategory.ON_THE_CUSP).concat(selectedTranscriptsIdsByCategory.STRUGGLING);

  const demographicData = demographicsResponse?.data?.total?.demographic_percentages;

  const memoizedDataForCharts = useMemo(() => {
    if (!demographicData) return null;

    const preparedData = prepareDemographicData(demographicData);

    return createChartConfigs(preparedData, lightGreenBarColor);
  }, [demographicData]);
 
  const memoizedSelectionChartData = useMemo(() => {
    if (!allSelectedTrascriptsIds.length) return null;

    const selectedTranscripts = allTrascripts.filter(transcript => allSelectedTrascriptsIds.includes(transcript.id));
    const selectedTranscriptsDemographicPercentages = calcSelectedTranscriptsDemographicPercentages(demographicsResponse?.data, selectedTranscripts);
    
    return createChartConfigs(selectedTranscriptsDemographicPercentages, darkGreenBarColor);
  }, [allSelectedTrascriptsIds.length, demographicsResponse?.data]);

  if (isPending) return <Loader/>;
  if (error) return <p>{error.message}</p>;

  return (
    <>
      { !memoizedDataForCharts ? <Loader /> : null }
      { memoizedDataForCharts && memoizedDataForCharts.map(({ title, labels, dataSet }, index) => {
        let dataSets = [dataSet];
        if (memoizedSelectionChartData) {
          dataSets = dataSets.concat(memoizedSelectionChartData[index].dataSet);
        } else {
          dataSets = dataSets.concat([{ data: new Array(dataSet.data.length).fill(0), backgroundColor: '' }]);
        }
        return (
          <React.Fragment key={index}>
            <div className="drawer-section in-transcript-selection">
              <p className="section-title in-transcript-selection">{title}</p>
              <div className="transcript-selection-chart-legend">
                <div className="transcript-selection-chart-legend-label">
                  <div style={{ backgroundColor: 'var(--accent-green-main)' }} className="color-dot"></div>
                  <p>School demographics</p>
                </div>
                <div className="transcript-selection-chart-legend-label">
                  <div style={{ backgroundColor: 'var(--accent-green-dark)' }} className="color-dot"></div>
                  <p>Your selection</p>
                </div>
              </div>
              <HorizontalBarChart
                labels={labels}
                dataSets={dataSets}
                customBarPercentage={1}
                customCategoryPercentage={0.7}
                customLayoutPaddingRight={40}
                dataLabelsFormatter={(value) => `${value}%`}/>

            </div>
            <CustomDivider orientation="horizontal" />
          </React.Fragment>
        );
      })}
    </>
  );
}

export default TranscriptSelectionDrawerContent;