import PageHeader from '../reusable/PageHeader';
import { USER_ROLES } from '../../constants';
import useUserDetails from '../hooks/useUserDetails';
import Loader from '../reusable/Loader';
import { DistrictDataLeadView, FacilitatorView } from './OverviewContent';

import './Overview.css';

function Overview() {

  const { isPending, error, data: userDetails } = useUserDetails();

  if (isPending) return <Loader />;
  if (error || !userDetails) return <p>Missing user details data!</p>;

  return (
    <>
      <PageHeader title="Data overview" />
      <div className="main-content">
        { userDetails.role === USER_ROLES.DISTRICT_DATA_LEAD ? 
          <DistrictDataLeadView userDetails={userDetails} />
          :
          null
        }
        { userDetails.role === USER_ROLES.FACILITATOR ? 
          <FacilitatorView userDetails={userDetails} />
          :
          null
        }
      </div>
    </>
  );
}

export default Overview;